import { navigate } from 'astro:transitions/client'
import { createSignal } from 'solid-js'
import type { DOMElement } from 'solid-js/jsx-runtime'
import { z } from 'zod'

import { Input, Typography } from '@/components'
import {
  useAuthentication,
  LOGIN_ERROR_FEEDBACKS,
} from '@/hooks/useAuthentication'
import { trackFormSubmission } from '@/utils/analytics'
import IBMLoginButton from './IbmButton'

interface ModalElement extends Element {
  showModal: () => void
  close: () => void
}

const URI = encodeURIComponent(import.meta.env.PUBLIC_CLEVER_REDIRECT_URI_URL)
const CLEVER_CLIENT_ID = import.meta.env.CLEVER_CLIENT_ID
const CLEVER_DISTRICT_ID = import.meta.env.CLEVER_DISTRICT_ID

const schema = z.object({
  studentCode: z
    .string()
    .min(1, LOGIN_ERROR_FEEDBACKS['CODE_MISSING'].key)
    .length(5, LOGIN_ERROR_FEEDBACKS['INVALID_CODE'].key)
    .or(z.string().length(10, LOGIN_ERROR_FEEDBACKS['INVALID_CODE'].key)),
})

const LoginForm = () => {
  const { auth, handleErrorFromIdentifier } = useAuthentication()

  const [studentCode, setStudentCode] = createSignal('')
  const [loading, setLoading] = createSignal(false)
  const [modalRef, setModalRef] = createSignal<ModalElement | undefined>()
  const [error, setError] = createSignal<string>('')

  const addLoading = () => {
    const loadingModalReference = modalRef()
    if (loadingModalReference) {
      loadingModalReference.showModal()
    }
    setLoading(true)
  }

  const removeLoading = () => {
    const loadingModalReference = modalRef()
    if (loadingModalReference) {
      loadingModalReference.close()
    }
    setLoading(false)
  }

  const handleLogin = async (data: { studentCode: string }) => {
    trackFormSubmission('Login', { studentCode: data.studentCode })

    try {
      addLoading()
      await auth(data.studentCode)
      await navigate('/home')
    } catch (error) {
      setError(error as string)
    } finally {
      removeLoading()
    }
  }

  const handleSubmit = (
    event: Event & {
      submitter: HTMLElement
    } & {
      currentTarget: HTMLFormElement
      target: DOMElement
    },
  ) => {
    event.preventDefault()

    const result = schema.safeParse({ studentCode: studentCode() })
    if (result.success) {
      handleLogin(result.data)
    } else {
      const errorIdentifier =
        result.error.format().studentCode?._errors[0] ?? ''
      setError(handleErrorFromIdentifier(errorIdentifier))
    }
  }

  const handleInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement
    const cleanValue = target.value.replace(/[^a-zA-Z0-9]/g, '')

    setStudentCode(cleanValue)
    target.value = cleanValue
  }

  return (
    <div class="z-10">
      <div class="flex w-full flex-col items-center justify-center gap-4 sm:gap-10 h-sm:gap-4">
        <Typography
          element="h1"
          size="h-sm"
          weight="bold"
          class="text-center text-white sm:text-5xl h-sm:text-2xl"
        >
          Student log in
        </Typography>

        <form
          onSubmit={(event) => handleSubmit(event)}
          class="flex flex-col items-center justify-center gap-4"
        >
          <div class="flex flex-col items-center justify-center gap-2">
            <Typography
              element="span"
              size="t-lg"
              class="font-medium text-white h-sm:hidden"
            >
              Enter your code
            </Typography>
            <Input
              class="uppercase placeholder:normal-case"
              name="studentCode"
              placeholder="Enter code"
              type="text"
              value={studentCode()}
              onInput={handleInputChange}
              status={error() ? 'error' : undefined}
              maxLength={10}
              data-testid="input-student-code"
            />
            {/* TODO: ADD the link again when we have the page link to redirect */}
            {/*<a href="" class="link text-base font-bold text-white">
              Where is my code?
            </a>*/}
          </div>

          <div class="flex flex-col items-center justify-center gap-4">
            <button
              id="login-button"
              type="submit"
              class="btn-primary-edsoma"
              disabled={loading()}
              data-testid="button-login"
            >
              {loading() ? (
                <span class="loading loading-spinner loading-xs" />
              ) : (
                'Next'
              )}
            </button>
            <Typography
              element="span"
              size="t-lg"
              weight="medium"
              class="hidden text-white sm:block h-sm:hidden"
            >
              Or
            </Typography>
            <a
              href={`https://clever.com/oauth/authorize?client_id=${CLEVER_CLIENT_ID}&district_id=${CLEVER_DISTRICT_ID}&redirect_uri=${URI}&response_type=code`}
              class="flex h-14 w-60 items-center justify-center gap-2 rounded-full bg-white text-center text-base font-bold text-black shadow-md hover:bg-caribbean-green-10 active:shadow-lg disabled:cursor-not-allowed disabled:opacity-50 h-sm:h-10"
            >
              <img src="/clever-icon.png" class="h-7 w-7" alt="Clever icon" />
              <span>Clever</span>
            </a>

            <IBMLoginButton />
          </div>
        </form>
      </div>

      <dialog id="modal" ref={(el) => setModalRef(el)} class="modal">
        <div class="modal-box w-auto bg-white p-0">
          <lottie-player
            src="https://lottie.host/5bc7a36c-83a9-431f-ba37-a434590c04ba/3qBcDKFlQV.json"
            background="##ffffff"
            speed="1"
            style={{ width: '150px', height: '150px' }}
            loop
            autoplay
            direction="1"
            mode="normal"
          />
        </div>
      </dialog>
    </div>
  )
}

export default LoginForm
