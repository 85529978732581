---
import { cn } from '@/utils'

interface Props {
  footerClass?: string
}

const { footerClass } = Astro.props
---

<div
  id="footer"
  class={cn(
    'fixed bottom-0 right-0 left-0 bg-bg-violet pt-4 pb-8 h-sm:pt-2 h-sm:pb-4',
    footerClass,
  )}
>
  <slot />
</div>

<script>
  function setFooterShadow() {
    const footerElement = document.getElementById('footer')
    const bodyHeight = document.body.scrollHeight
    const windowHeight = window.innerHeight

    if (bodyHeight > windowHeight) {
      footerElement?.classList.add('shadow-footer')
    } else {
      footerElement?.classList.remove('shadow-footer')
    }
  }

  function footerShadowHandler() {
    setFooterShadow()

    new ResizeObserver(() => {
      setFooterShadow()
    }).observe(document.body)
  }

  footerShadowHandler()

  document.addEventListener('astro:after-swap', () => {
    footerShadowHandler()
  })
</script>
