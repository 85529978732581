import { LeftArrowIcon } from '@/components'
import { cn } from '@/utils'

const BackButton = (props: {
  class?: string
  id?: string
  white?: boolean
}) => {
  const handleBack = () => {
    history.back()
  }

  return (
    <button
      class={cn(
        'flex h-14 min-h-14 w-14 min-w-14 items-center justify-center rounded-full border border-solid',
        props.white ? 'border-white' : 'border-violet-60',
        props.class || '',
      )}
      id={props?.id || ''}
      onClick={handleBack}
    >
      <LeftArrowIcon
        class="relative"
        color={props.white ? 'white' : '#7C4BE2'}
        width={24}
        height={24}
      />
    </button>
  )
}

export default BackButton
