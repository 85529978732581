import { splitProps, type JSX } from 'solid-js'

interface IconProps extends JSX.SvgSVGAttributes<SVGSVGElement> {
  color: string
}

const Menu = (_props: IconProps) => {
  const [{ height = 25, width = 25, color = '#fff' }, rest] = splitProps(
    _props,
    ['height', 'width', 'color'],
  )

  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${height} ${width}`}
      height={height}
      width={width}
      fill="none"
    >
      <g id="menu">
        <path
          id="Icon"
          d="M3 12H21M3 6H21M3 18H21"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default Menu
