import { createSignal, onMount, Show } from 'solid-js'

import { CrossIcon, Typography, YoutubeVideo } from '@/components'
import { pauseVideoSafely } from '@/utils'
import { trackButtonClick } from '@/utils/analytics'

const DrawerVideoDialog = (props: { videoID: string; title: string }) => {
  const [youtubeVideoPlayer, setYoutubeVideoPlayer] =
    createSignal<YT.Player | null>(null)
  const [modalRef, setModalRef] = createSignal<HTMLDialogElement>()

  function showVideoDialog() {
    trackButtonClick(`${props.title} Video Dialog Open`)
    modalRef()?.showModal()
  }

  onMount(() => {
    modalRef()?.addEventListener('close', () => {
      pauseVideoSafely(youtubeVideoPlayer())
    })
  })

  return (
    <Show when={props.videoID}>
      <div class="flex w-full items-center justify-center">
        <button
          class="w-full rounded-xl bg-violet-60 py-3 hover:bg-violet-80"
          onClick={showVideoDialog}
        >
          <Typography
            weight="semibold"
            size="t-lg"
            element="span"
            class="text-white"
          >
            {props.title}
          </Typography>
        </button>

        <dialog id="modal" class="modal" ref={(el) => setModalRef(el)}>
          <div class="modal-box w-10/12 max-w-3xl">
            <form method="dialog">
              <button
                onClick={() => trackButtonClick(`${props.title} Video Close`)}
                class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
              >
                <CrossIcon width={20} height={20} color="#7C4BE2" />
              </button>
            </form>

            <div class="flex w-full items-center justify-center">
              <YoutubeVideo
                iframeId={`youtube-video-${props.videoID}`}
                videoId={props.videoID}
                setYoutubePlayer={setYoutubeVideoPlayer}
              />
            </div>
          </div>
        </dialog>
      </div>
    </Show>
  )
}

export default DrawerVideoDialog
