import { Typography } from '@/components'
import type { Suggestion as Interest } from '@/core/entities'
import { categoryTransform } from '@/utils'

interface InterestCardProps {
  interest?: Interest
  title: string
}

const InterestCard = (props: InterestCardProps) => {
  return (
    <div class="flex w-full min-w-24 flex-col items-center justify-center gap-0 overflow-hidden rounded-xl border border-solid border-violet-50 bg-bg-onboarding-selected-card bg-white shadow-light-purple md:w-36 md:pb-4 lg:w-44  lg:pb-4 h-sm:w-36 h-sm:gap-3 h-sm:pb-2">
      <div class="flex w-full items-center justify-center bg-yellow-60">
        <Typography
          element="span"
          size="t-sm"
          weight="normal"
          class="truncate text-edsm-neutral-100"
        >
          {categoryTransform(props.title)}
        </Typography>
      </div>

      <div class="flex w-full flex-col items-center  justify-center gap-2 overflow-hidden rounded-none bg-white p-3 md:h-36 md:w-32 md:rounded-xl lg:h-36 lg:w-32 lg:rounded-xl h-sm:h-28 h-sm:w-24 ">
        <img
          src={props.interest?.imageUrl}
          alt={props.interest?.name}
          class="hidden flex-1 overflow-hidden md:block lg:block"
        />

        <Typography
          element="span"
          size="t-md"
          weight="medium"
          class="max-w-full truncate text-edsm-neutral-100"
        >
          {props.interest?.name}
        </Typography>
      </div>
    </div>
  )
}

export default InterestCard
