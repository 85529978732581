import { For } from 'solid-js'

const LoadingBooks = () => {
  return (
    <div class="mt-10 flex w-full flex-col items-center justify-center">
      <div class="mb-6 h-11 w-64 rounded-md bg-edsm-neutral-50" />
      <div class="grid w-full grid-cols-2 items-center justify-between justify-items-center gap-6 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        <For each={Array.from({ length: 6 })}>
          {() => (
            <div class="h-72 w-60 animate-pulse rounded-xl bg-edsm-neutral-50" />
          )}
        </For>
      </div>
    </div>
  )
}

export default LoadingBooks
