---
import { Image } from 'astro:assets'

import { InterestCard, Typography } from '@/components'
import type { Student } from '@/core/entities'
import { cn, getAgeByBirthday } from '@/utils'

interface Props {
  cardClass?: string
}

const { cardClass } = Astro.props

const userCookie = Astro.cookies.get('user')
let user = userCookie?.value ? (JSON.parse(userCookie.value) as Student) : null

const userAge = user ? getAgeByBirthday(user.birthDate) : null

const userInterests = user?.interests || []
---

<div
  class={cn(
    'flex flex-col gap-4 md:flex-row p-6 rounded-2xl items-center justify-between bg-gradient-to-b from-gradient-color-violet-from to-gradient-color-violet-to w-full border border-solid border-violet-50 shadow-light-purple',
    cardClass,
  )}
>
  <div
    class="flex flex-row md:flex-col items-center justify-center self-start md:self-auto w-auto gap-2 lg:min-w-[330px] h-full"
  >
    <Image
      class="rounded-full border-4 border-solid border-tertiary w-[64px] h-[64px] md:h-[104px] md:w-[104px] landscape:h-sm:w-12 landscape:h-sm:h-12"
      src={user?.imageUrl || ''}
      alt={user?.name || ''}
      width={104}
      height={104}
    />

    <div class="flex flex-col gap-1 md:items-center">
      <Typography
        element="h2"
        size="t-xs"
        weight="bold"
        class="text-white text-lg whitespace-nowrap"
      >
        {user?.name.replace('name', '')}
      </Typography>
      <div class="inline-flex gap-3 items-center justify-center text-white">
        {
          userAge ? (
            <>
              <Typography
                element="span"
                size="t-md"
                weight="semibold"
                class="whitespace-nowrap"
              >
                {['Age', userAge].join(' ')}
              </Typography>
              <div class="rounded-full border-3 border-solid border-white" />
            </>
          ) : (
            ''
          )
        }

        <Typography
          element="span"
          size="t-md"
          weight="medium"
          class="whitespace-nowrap"
        >
          {[user?.grade, 'Grade'].join(' ')}
        </Typography>
      </div>
    </div>
  </div>
  <div
    class="grid grid-cols-3 gap-1.5 w-full md:gap-6 md:w-auto lg:w-auto md:flex lg:flex justify-items-center"
  >
    {
      userInterests?.map(({ item, category }) => (
        <InterestCard title={category.name} interest={item} />
      ))
    }
  </div>
</div>
