import { Show, createEffect, createMemo, createSignal } from 'solid-js'

import { favoriteBook } from '@/core/infra/graphql'
import { cn } from '@/utils'

import { HeartIcon } from '.'
import Typography from './Typography'
import { trackButtonClick } from '@/utils/analytics'

interface ModalElement extends Element {
  showModal: () => void
  close: () => void
}

interface FavoriteBookButtonProps {
  childId: number
  bookId: number
  isFavorite: boolean
  favoritePosition?: 'bottom-right' | 'top-right'
  favoriteClass?: string
  callbackFavotire?: (bookId: number) => void
}

const FavoriteBookButton = (props: FavoriteBookButtonProps) => {
  const [modalRef, setModalRef] = createSignal<ModalElement>()
  const [isFavorite, setIsFavorite] = createSignal<boolean>(false)

  const handleOpenModal = (event: MouseEvent) => {
    event.stopImmediatePropagation()
    modalRef()?.showModal()
  }

  const positionClass = {
    'bottom-right': 'absolute bottom-16 right-2',
    'top-right': 'absolute top-2 right-2',
  }

  const favoritePosition = createMemo(() => {
    return props.favoritePosition || 'top-right'
  })

  createEffect(() => {
    setIsFavorite(props.isFavorite)
  })

  const handleFavoriteButton = (event: MouseEvent) => {
    event.stopImmediatePropagation()

    if (isFavorite()) {
      handleOpenModal(event)
    } else {
      handleFavoriteAction()
    }
  }

  const handleFavoriteAction = async () => {
    trackButtonClick('Favorite Book', {
      bookId: props.bookId,
    })

    try {
      const { favoriteOrUnfavoriteBook } = await favoriteBook({
        variables: {
          bookId: props.bookId,
          childId: props.childId,
          favorite: !props.isFavorite,
        },
      })

      if (favoriteOrUnfavoriteBook.success) {
        modalRef()?.close()
        props.callbackFavotire?.(props.bookId)
      }
    } catch (error) {
      const favoriteAction = props.isFavorite ? 'unfavorite' : 'favorite'
      const errorMessage = `Failed to ${favoriteAction} the book`
      addToast('Code not found', errorMessage)
      console.error(error)
    }
  }

  return (
    <>
      <dialog
        id="modal"
        ref={(el) => setModalRef(el)}
        onClick={(e: MouseEvent) => e.stopImmediatePropagation()}
        class="modal"
      >
        <div class="modal-backdrop" onClick={() => modalRef()?.close()} />
        <div class="modal-box flex flex-col items-center justify-center gap-10 bg-white p-10">
          <Typography
            weight="bold"
            size="h-md"
            element="h2"
            class="text-center text-edsm-neutral-100"
          >
            Remove from favorites?
          </Typography>
          <div class="mt-8 flex w-full items-center justify-center gap-6">
            <button
              class="btn-secondary-edsoma w-full"
              onClick={() => modalRef()?.close()}
            >
              Cancel
            </button>
            <button
              onClick={handleFavoriteAction}
              class="btn-primary-edsoma w-full"
            >
              Confirm
            </button>
          </div>
        </div>
      </dialog>
      <button
        onClick={handleFavoriteButton}
        class={cn(
          'link-white-b-violet z-10 flex h-9 w-9 items-center justify-center p-2',
          positionClass[favoritePosition()],
          isFavorite() && 'bg-violet-60 hover:bg-violet-70',
          props.favoriteClass,
        )}
      >
        <Show
          when={isFavorite()}
          fallback={
            <HeartIcon
              width={25}
              height={25}
              color="#7C4BE2"
              fillColor="none"
            />
          }
        >
          <HeartIcon
            width={25}
            height={25}
            color="#FFFFFF"
            fillColor="#FFFFFF"
          />
        </Show>
      </button>
    </>
  )
}

export default FavoriteBookButton
