export function categoryTransform(phrase: string): string {
  const categoryMapping: Record<string, string> = {
    'Your Hobbies': 'Hobby',
    'Favorite Animal': 'Animal',
    'Book Category': 'Book Category',
  }

  const transformedCategory = categoryMapping[phrase]

  return transformedCategory || phrase
}
