import { useStore } from '@nanostores/solid'
import { Show, createMemo, onMount } from 'solid-js'

import { BookInstructionsDialog, FavoriteBookButton } from '@/components'
import {
  bookDetail as storeBookDetail,
  getDetailBook,
  handleBookFavorite,
  loadingBookDetail,
  bookVideoStore,
  showBookVideo,
  bookCallbackStore,
} from '@/store'
import { cn, getSpeechBookReadingUrl } from '@/utils'

import Typography from './Typography'
import { trackButtonClick } from '@/utils/analytics'

interface BookDetailProps {
  id: string
  childId: number
}

const BookDetail = (props: BookDetailProps) => {
  const book = useStore(storeBookDetail)
  const loading = useStore(loadingBookDetail)
  const bookVideo = useStore(bookVideoStore)

  onMount(() => {
    if (props?.id) {
      getDetailBook(Number(props?.id), props.childId)
    }
  })

  const createHref = createMemo(() => {
    if (book() && book()?.webBookUrl) {
      const bookId = book()?.id
      return getSpeechBookReadingUrl(bookId)
    }
  })

  function redirectToBook() {
    if (createHref()) {
      window.location.href = createHref() || ''
    }
  }

  function handleReadBook() {
    const status = bookVideo()?.status
    console.log(status)
    if (status === 'PENDING') {
      bookCallbackStore.set(() => redirectToBook())

      showBookVideo()
    } else {
      trackButtonClick('Read', { bookId: book().id })
      redirectToBook()
    }
  }

  const bookIsFavorite = createMemo(() => {
    return book()?.childBook?.isFavorite
  })

  return (
    <div
      class={cn(
        'flex flex-row items-center justify-center gap-4 px-5',
        'md:gap-6 portrait:flex-col portrait:md:px-24 landscape:lg:gap-12 landscape:lg:px-20',
      )}
    >
      <BookInstructionsDialog childId={props.childId} />
      <Show when={!loading()} fallback={<>loading...</>}>
        <div
          class={cn(
            'relative flex h-64 w-44 items-center justify-center',
            'portrait:md:h-96 portrait:md:w-64 landscape:min-w-[176px] landscape:lg:h-[534px] landscape:lg:w-96 landscape:lg:min-w-[unset]',
          )}
        >
          <img
            src={book()?.imageUrl}
            width={390}
            height={534}
            class={cn(
              'h-full max-w-none',
              'landscape:h-auto landscape:min-w-[176px]',
            )}
          />

          <FavoriteBookButton
            bookId={book().id}
            isFavorite={bookIsFavorite()}
            childId={props.childId}
            callbackFavotire={handleBookFavorite}
            favoritePosition="bottom-right"
            favoriteClass="-bottom-1 w-14 h-14"
          />
        </div>
        <div class="flex flex-col items-center justify-center">
          <Typography
            element="span"
            size="t-md"
            weight="normal"
            class="text-edsm-neutral-80"
          >
            {book()?.author}
          </Typography>

          <Typography
            element="span"
            size="h-xs"
            weight="bold"
            class={cn('mt-2 text-edsm-neutral-100', 'md:text-3xl')}
          >
            {book()?.name}
          </Typography>

          <div class="mt-4 flex flex-row gap-2">
            <Typography
              element="span"
              size="t-md"
              weight="normal"
              class="rounded-full bg-violet-60 px-3 py-2 text-white"
            >
              {book()?.category?.name}
            </Typography>

            <Typography
              element="span"
              size="t-md"
              weight="normal"
              class="rounded-full bg-violet-60 px-3 py-2 text-white"
            >
              Ages {book()?.ageRange?.range}
            </Typography>
          </div>

          <Typography
            element="p"
            size="t-md"
            weight="normal"
            class={cn(
              'mt-6 text-edsm-neutral-100',
              'portrait:md:mt-12 portrait:md:text-xl landscape:lg:text-xl',
            )}
          >
            {book()?.details}
          </Typography>

          <button
            disabled={!bookVideo()?.status}
            onClick={() => handleReadBook()}
            class={cn(
              'btn-primary-edsoma mt-6 flex h-10 w-40 items-center justify-center',
              'portrait:md:mt-12 portrait:md:h-14 portrait:md:w-56 landscape:lg:mt-12 landscape:lg:h-14 landscape:lg:w-56',
            )}
          >
            <Typography
              element="span"
              size="t-md"
              weight="bold"
              class={cn('text-edsm-neutral-100', 'md:text-lg')}
            >
              Read
            </Typography>
          </button>
        </div>
      </Show>
    </div>
  )
}

export default BookDetail
