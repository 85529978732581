import { splitProps, type JSX } from 'solid-js'

interface IconProps extends JSX.SvgSVGAttributes<SVGSVGElement> {
  color: string
  fillColor?: string
}

const FlashIcon = (_props: IconProps) => {
  const [{ height = '100%', width = '100%', fillColor = 'none', color }, rest] =
    splitProps(_props, ['height', 'width', 'color', 'fillColor'])

  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 16 16'}
      height={height}
      width={width}
      fill={fillColor}
    >
      <g id="flash">
        <path
          id="Icon"
          d="M8.66667 1.33337L2 9.33337H8L7.33333 14.6667L14 6.66671H8L8.66667 1.33337Z"
          stroke={color}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default FlashIcon
