import { type Component, For, Show, createSignal, onMount } from 'solid-js'
import { useStore } from '@nanostores/solid'
import { ImTrophy } from 'solid-icons/im'
import { navigate } from 'astro:transitions/client'

import { QuizLoading, QuizScore, Typography } from '@/components'
import type { QuizOption } from '@/core/entities'
import {
  loadQuiz,
  loadingQuizStore,
  loadingQuizAnswerStore,
  submitQuizAnswer,
  quizStore,
  setQuizNextQuestion,
} from '@/store'
import { cn } from '@/utils'
import { trackFormSubmission } from '@/utils/analytics'

interface QuizMainProps {
  bookId: string | undefined
  childId: number | undefined
}

const MainQuiz: Component<QuizMainProps> = (_props) => {
  const quiz = useStore(quizStore)
  const loadingQuiz = useStore(loadingQuizStore)
  const loadingQuizAnswer = useStore(loadingQuizAnswerStore)

  const [currentQuestionIndex, setCurrentQuestionIndex] =
    createSignal<number>(0)
  const [selectedOption, setSelectedOption] = createSignal<QuizOption | null>(
    null,
  )
  const [showScore, setShowScore] = createSignal<boolean>(false)

  const checkCurrentQuestion = () => {
    if (quiz()) {
      const submitedQuestions = quiz().questions.filter(
        (question) => question.optionId,
      )
      if (!submitedQuestions.length) return
      if (
        submitedQuestions.length &&
        submitedQuestions.length === quiz().questions.length
      ) {
        setShowScore(true)
        return
      }
      setCurrentQuestionIndex(submitedQuestions.length)
    }
  }

  const selectOption = (option: QuizOption) => {
    if (loadingQuizAnswer() || quiz().currentQuestion.optionId) return
    setSelectedOption(option)
  }

  const showNextQuestion = () => {
    if (quiz().currentQuestionIdx + 1 < quiz().questions.length) {
      setQuizNextQuestion()
    } else {
      setShowScore(true)
    }
  }

  const submitAnswer = async () => {
    const studentAnswer = selectedOption()
    if (
      loadingQuizAnswer() ||
      !quiz() ||
      !quiz().currentQuestion ||
      !studentAnswer
    )
      return

    const { childId } = quiz().questions[currentQuestionIndex()]

    const { questionId } = quiz().currentQuestion
    await submitQuizAnswer(childId, questionId, studentAnswer.id)
    trackFormSubmission('Quiz Answer', {
      childId: childId,
      questionId: questionId,
    })
  }

  onMount(async () => {
    if (!_props.bookId || !_props.childId) {
      return navigate('/home')
    }
    await loadQuiz(parseInt(_props.bookId), _props.childId)

    checkCurrentQuestion()
  })

  return (
    <Show when={!loadingQuiz() && quiz()} fallback={<QuizLoading />}>
      <div class="flex w-full max-w-5xl flex-col">
        <div
          class={cn(
            'relative flex h-40 items-center justify-center rounded-2xl',
            'bg-gradient-to-b from-gradient-color-violet-from to-gradient-color-violet-to',
          )}
        >
          <div class="absolute -top-10 flex h-20 w-20 items-center justify-center rounded-full border border-solid border-edsm-neutral-20 bg-white shadow-md">
            <Show
              when={!showScore()}
              fallback={<ImTrophy size={32} class="text-yellow-60" />}
            >
              <Typography element="span" size="h-xs" weight="semibold">
                {quiz().currentQuestionIdx + 1}/{quiz()?.questions?.length}
              </Typography>
            </Show>
          </div>

          <Typography element="h1" size="h-sm" weight="bold" class="text-white">
            {quiz()?.bookName || 'Book Quiz'}
          </Typography>
        </div>

        <section class="mx-auto flex h-full w-full flex-col gap-6 p-10">
          <Show when={!showScore()} fallback={<QuizScore />}>
            <Typography
              element="h2"
              size="h-xs"
              weight="bold"
              class="mx-auto text-edsm-neutral-100 md:text-4xl"
            >
              {quiz().currentQuestion?.question?.content}
            </Typography>

            <div class="flex w-full flex-col gap-4">
              <For each={quiz().currentQuestion?.question?.options}>
                {(option) => (
                  <button
                    class={cn(
                      'flex items-center justify-center rounded-3xl bg-edsm-neutral-10 px-10 py-4 text-base font-semibold text-edsm-neutral-100 shadow-md',
                      'hover:border-edsm-neutral-60 disabled:cursor-not-allowed',
                      {
                        'bg-tertiary text-white':
                          option.id === selectedOption()?.id,
                        'animate-purple-shadow-pulse':
                          option.id === selectedOption()?.id &&
                          loadingQuizAnswer(),
                        'bg-caribbean-green-60':
                          option.id === quiz().currentCorrectOption,
                        'bg-edsm-error-60':
                          quiz()?.currentCorrectOption &&
                          option.id === selectedOption()?.id &&
                          quiz()?.currentCorrectOption !==
                            quiz()?.currentAnswer,
                      },
                    )}
                    onClick={() => selectOption(option)}
                    disabled={!!quiz().currentQuestionOption}
                  >
                    {option.content}
                  </button>
                )}
              </For>
            </div>

            <Show when={selectedOption() && !quiz().currentQuestionOption}>
              <button
                class="btn-primary-edsoma animate-enter-right self-center"
                onClick={submitAnswer}
              >
                Submit answer
              </button>
            </Show>

            <Show when={quiz().currentQuestionOption}>
              <button
                class="btn-primary-edsoma animate-enter-right self-center md:self-end"
                onClick={showNextQuestion}
              >
                Next question
              </button>
            </Show>
          </Show>
        </section>
      </div>
    </Show>
  )
}

export default MainQuiz
