import { CheckIcon, Typography } from '@/components'
import type { BookCategory } from '@/core/entities'
import { cn } from '@/utils'

interface BookCategoryItemProps {
  category: BookCategory
  isSelected: boolean
  onSelect: () => void
}

const classSelect = (isSelected: boolean) =>
  isSelected ? 'border-4 border-yellow-60' : 'border-2 border-violet-80'

const innerClassSelect = (isSelected: boolean) =>
  isSelected ? 'h-28 w-28' : 'h-full w-full'

const BookCategoryItem = (props: BookCategoryItemProps) => {
  const handleSelect = () => {
    props.onSelect()
  }

  return (
    <button
      onClick={handleSelect}
      class="flex w-full flex-col items-center justify-center transition delay-150 duration-300 ease-in-out hover:-translate-y-2 hover:scale-110"
    >
      <div
        class={cn(
          'relative flex h-32 w-32 items-center justify-center rounded-full',
          classSelect(props.isSelected),
        )}
      >
        {props.isSelected && (
          <div class="absolute -top-4 flex h-6 w-6 items-center justify-center rounded-full bg-yellow-60">
            <CheckIcon color="#212121" width={18} height={20} />
          </div>
        )}
        <div
          class={cn(
            'flex h-28 w-28 items-center justify-center overflow-hidden rounded-full',
            innerClassSelect(props.isSelected),
          )}
        >
          <img srcSet={props.category.imageUrl} alt="category image" />
        </div>
      </div>
      <Typography element="span" size="t-lg" weight="medium" class="text-white">
        {props.category.name}
      </Typography>
    </button>
  )
}

export default BookCategoryItem
