import { For, Show, createSignal, onMount } from 'solid-js'
import { useStore } from '@nanostores/solid'

import { CheckIcon, CrossIcon, Typography } from '@/components'
import type { AgeRange } from '@/core/entities'
import { ageRanges, loadAgeRanges, loadingAgeRanges } from '@/store'
import { getSelectedAgeRangeStore } from '@/store/session'
import { cn, handleDrawerScroll } from '@/utils'

const closeDrawer = () => {
  if (!document) return

  const drawer = document.getElementById(
    'my-drawer-agerange',
  ) as HTMLInputElement

  if (!drawer) return
  drawer.click()
}

const AgeRangeDrawer = () => {
  const ageRangeList = useStore(ageRanges)
  const loading = useStore(loadingAgeRanges)
  const {
    value: selectedAgeRanges,
    setValue: setSelectedAgeRanges,
    clearSelectedAgeRanges,
  } = getSelectedAgeRangeStore()

  const [selectedItems, setSelectedItems] = createSignal<AgeRange[]>([])

  const toggleItem = (item: AgeRange) => {
    if (selectedItems().includes(item)) {
      setSelectedItems(selectedItems().filter((i) => i !== item))
    } else {
      setSelectedItems([...selectedItems(), item])
    }
  }

  const confirmSelection = () => {
    setSelectedAgeRanges(selectedItems())
    closeDrawer()
  }

  const clearSelection = () => {
    clearSelectedAgeRanges()
    setSelectedItems([])
    closeDrawer()
  }

  const handleChange = (
    e: Event & {
      currentTarget: HTMLInputElement
      target: HTMLInputElement
    },
  ) => {
    if (e.currentTarget.checked) {
      setSelectedItems(selectedAgeRanges())
    }
  }

  onMount(() => {
    loadAgeRanges()
    handleDrawerScroll('my-drawer-agerange')
  })

  return (
    <div class="drawer drawer-end">
      <input
        id="my-drawer-agerange"
        type="checkbox"
        class="drawer-toggle"
        onChange={(event) => handleChange(event)}
      />
      <div class="drawer-content" />
      <div class="drawer-side z-30">
        <label
          for="my-drawer-agerange"
          aria-label="close sidebar"
          class="drawer-overlay checked:bg-edsm-neutral-100 checked:opacity-50"
        />
        <div class="menu  flex h-full w-[100%] flex-row justify-between border border-solid border-violet-50 bg-white  p-0 text-base-content md:w-[480px]">
          <div class="flex h-screen w-full flex-col items-center justify-center">
            <div
              class={cn('flex w-full px-6 py-6', 'md:px-8 landscape:h-md:pb-0')}
            >
              <button
                onClick={closeDrawer}
                class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-violet-60"
              >
                <CrossIcon width={20} height={20} color="#7C4BE2" />
              </button>
            </div>

            <Typography
              element="span"
              size="t-lg"
              weight="normal"
              class={cn('my-4 text-edsm-neutral-100', 'landscape:h-md:my-2')}
            >
              {selectedItems().length
                ? [selectedItems().length, 'selected'].join(' ')
                : 'Select age range'}
            </Typography>

            <div class="flex h-full w-full grid-cols-1 flex-col gap-4 overflow-auto overflow-x-hidden px-8 pt-4">
              <Show when={!loading()}>
                <For each={ageRangeList()}>
                  {(item) => (
                    <label
                      class={cn(
                        'relative flex h-[72px] w-full cursor-pointer flex-row items-center gap-3 rounded-xl border-2 border-violet-60 bg-pale-violet-10 p-6 transition delay-150 duration-300',
                        'hover:scale-105 hover:border-violet-80 hover:shadow-sm',
                        '[&:has(input:checked)]:bg-pale-violet-20 [&:has(input:checked)]:outline [&:has(input:checked)]:outline-4 [&:has(input:checked)]:-outline-offset-1 [&:has(input:checked)]:outline-violet-60',
                      )}
                      tabIndex={0}
                    >
                      <input
                        type="checkbox"
                        name="age-range-selection"
                        class="peer sr-only"
                        checked={selectedItems().includes(item)}
                        onClick={() => toggleItem(item)}
                      />

                      <div
                        class={cn(
                          'h-6 min-h-6 w-6 rounded-sm border border-solid border-violet-60 transition-all delay-150 duration-150 h-sm:h-4 h-sm:min-h-4 h-sm:w-4',
                          'peer-checked:border-6 peer-checked:bg-violet-60 h-sm:peer-checked:border-3',
                        )}
                      />
                      <div class="invisible absolute left-[27px] top-[25px] transition-all delay-200 duration-200 peer-checked:visible ">
                        <CheckIcon width={20} height={20} color="white" />
                      </div>

                      <Typography
                        element="span"
                        size="t-md"
                        weight="medium"
                        class="text-black h-sm:text-sm"
                      >
                        {item.range}
                      </Typography>
                    </label>
                  )}
                </For>
              </Show>
            </div>

            <div class="grid w-full gap-6 bg-white p-8 pt-6 md:grid-cols-2">
              <button
                onClick={clearSelection}
                class="btn-secondary-edsoma w-full "
              >
                Clear All
              </button>

              <button
                onClick={confirmSelection}
                class="btn-primary-edsoma w-full"
              >
                {selectedItems().length ? 'Confirm' : 'Show All Ages'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgeRangeDrawer
