export type Student = App.Locals['user']

export enum StudentInstructions {
  ONBOARDING_VIDEO = 'ONBOARDING_VIDEO',
  BOOK_READING_TUTORIAL = 'BOOK_READING_TUTORIAL',
}

export type Instruction = {
  id: number
  name: string
  contentSource: string
}

export type StudentInstruction = {
  id: number
  childId: number
  instruction: Instruction
  status: 'COMPLETED' | 'PENDING'
}
