export function getYoutubeVideoIDFromURL(videoURL: string): string | undefined {
  const URLMatch = videoURL.match(
    /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  )
  if (URLMatch && URLMatch[1]) return URLMatch[1]
}

export const pauseVideoSafely = (player: YT.Player | null): boolean => {
  if (player) {
    if (typeof player.pauseVideo === 'function') {
      player.pauseVideo()
      return true
    }
  }
  return false
}
