import { AiOutlineNotification } from 'solid-icons/ai'

import * as Sentry from '@sentry/browser'

async function feedbackAction() {
  const feedbackBackend = Sentry.feedbackIntegration({
    colorScheme: 'light',
    isEmailRequired: true,
    isNameRequired: true,
  })
  const form = feedbackBackend
    ? await feedbackBackend.createForm().catch(() => null)
    : null
  if (form) {
    form.appendToDom()
    form.open()
  }
}

const SentryFeedback = () => {
  return (
    <div class="fixed bottom-4 left-4">
      <button
        onClick={() => feedbackAction()}
        type="button"
        class="btn rounded-full border-2 border-edsm-neutral-30 bg-white text-edsm-neutral-100 shadow-md hover:bg-edsm-neutral-10"
        aria-hidden="false"
        aria-label="Report a Bug"
      >
        <AiOutlineNotification size={24} />
        <span class="hidden sm:block">Report a Bug</span>
      </button>
    </div>
  )
}
export default SentryFeedback
