import { createSignal } from 'solid-js'
import { makePersisted } from '@solid-primitives/storage'

import type { SessionStorage } from '@/store/session'

interface SelectedAuthorStore extends SessionStorage<string> {}

const SELECTED_AUTHOR_KEY = 'selectedAuthor'
let selectedAuthorStore: SelectedAuthorStore | undefined = undefined

export const getSelectedAuthorStore = (): SelectedAuthorStore => {
  if (selectedAuthorStore) return selectedAuthorStore

  const [selectedAuthor, setSelectedAuthor] = makePersisted(
    createSignal<string>(''),
    {
      storage: sessionStorage,
      name: SELECTED_AUTHOR_KEY,
    },
  )

  selectedAuthorStore = {
    value: selectedAuthor,
    setValue: setSelectedAuthor,
  }

  return selectedAuthorStore
}
