import { For, createMemo, createSignal } from 'solid-js'

import { BookLine, Typography } from '@/components'
import type { Book } from '@/core/entities'
import { cn } from '@/utils'
import { trackButtonClick } from '@/utils/analytics'

export type YourBooks = Pick<
  Book,
  'id' | 'name' | 'author' | 'childBook' | 'imageUrl' | 'webBookUrl'
>

type TabType = 'allBooks' | 'assigned'

const YourBooksList = (props: {
  books: YourBooks[]
  startingTab: 'assigned' | 'allBooks'
}) => {
  const [activeTab, setActiveTab] = createSignal<TabType>(props.startingTab)

  const hideTabs = createMemo(() => props.startingTab === 'allBooks')

  const tabButtonClasses = (tab: TabType) =>
    `rounded-full px-4 py-2 ${
      activeTab() === tab
        ? 'bg-violet-60 text-white'
        : 'bg-edsm-neutral-10 text-edsm-neutral-100'
    }`

  const handleTabClick = (tab: TabType) => {
    trackButtonClick('Tab', { tab })
    setActiveTab(tab)
  }

  const booksValue = createMemo<YourBooks[]>(() => {
    if (activeTab() === 'assigned') {
      return props.books.filter((book) => book.childBook.isAssigned)
    } else {
      return props.books
    }
  })

  return (
    <div class="mt-10 flex w-full flex-col items-center justify-center">
      <Typography
        element="h1"
        size="h-md"
        weight="bold"
        class="mb-6 text-edsm-neutral-100"
      >
        Your books
      </Typography>

      <div class={cn('mb-6', { hidden: hideTabs() })}>
        <div class="flex rounded-full bg-edsm-neutral-10 p-2">
          <button
            class={tabButtonClasses('assigned')}
            onClick={() => handleTabClick('assigned')}
          >
            <Typography element="span" size="t-md" weight="bold">
              Assigned
            </Typography>
          </button>
          <button
            class={tabButtonClasses('allBooks')}
            onClick={() => handleTabClick('allBooks')}
          >
            <Typography element="span" size="t-md" weight="bold">
              All Books
            </Typography>
          </button>
        </div>
      </div>

      <div class="flex w-full flex-col gap-6">
        <For each={booksValue()}>
          {(book) => <BookLine booksDetail={book} />}
        </For>
      </div>

      <div class="mt-16 flex w-full items-center justify-center">
        <a
          class="btn-primary-edsoma flex items-center justify-center p-4"
          href="/library"
        >
          See more books
        </a>
      </div>
    </div>
  )
}

export default YourBooksList
