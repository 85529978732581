---
import { Image } from 'astro:assets'

import Confetti from '@/assets/well_done_confetti.svg?url'
import { Typography } from '@/components'
---

<dialog class="modal" id="finish-modal">
  <div
    class="modal-box bg-white flex flex-col p-10 items-center md:w-96 landscape:h-md:px-8 landscape:h-md:py-4 landscape:h-md:w-auto"
  >
    <Typography
      element="h1"
      size="h-md"
      weight="bold"
      class="text-edsm-neutral-100 landscape:h-md:text-2xl"
    >
      Well done!
    </Typography>

    <Image
      src={Confetti}
      alt="Well done image"
      width={250}
      height={250}
      class="landscape:h-md:w-36 landscape:h-md:h-32"
    />

    <a
      class="btn-primary-edsoma flex items-center justify-center"
      id="finish-modal-btn"
      href="/home"
    >
      Next
    </a>
  </div>
</dialog>
