import { navigate } from 'astro:transitions/client'
import Typography from './Typography'
import { LogoutIcon } from '.'
import { trackButtonClick } from '@/utils/analytics'

const LogoutButton = () => {
  const handleLogout = async () => {
    const typeLogin = localStorage.getItem('@eds:login')

    const isIbmLogin = typeLogin === 'ibm'

    if (isIbmLogin) {
      try {
        const url = [
          import.meta.env.PUBLIC_API_URL,
          'auth/ibm/url/logout',
        ].join('/')

        const request = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        localStorage.removeItem('@eds:login')
        sessionStorage.clear()

        if (request.ok) {
          const result = await request.json()
          const { data } = result
          window.location.href = data
        }
      } catch (err) {
        throw new Error(err as string)
      }
    }

    trackButtonClick('Logout')
    document.cookie =
      'authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

    return navigate('/login')
  }

  return (
    <button
      class="flex w-full rounded-lg bg-transparent px-6 pb-8 pt-6"
      onClick={handleLogout}
    >
      <Typography
        element="span"
        size="t-lg"
        weight="semibold"
        class="mr-4 w-auto  text-edsm-neutral-100"
      >
        Sign out
      </Typography>
      <LogoutIcon color="#101828" width={25} height={25} />
    </button>
  )
}

export default LogoutButton
