import { splitProps, type JSX, createMemo } from 'solid-js'
import { Dynamic } from 'solid-js/web'

import { cn } from '@/utils'

type TypographyElement = 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

/**
 * Typography element size
 *
 * * h-2xl (72px-90px)
 * * h-xl (60px-72px)
 * * h-lg (48px-60px)
 * * h-md (36px-44px)
 * * h-sm (30px-38px)
 * * h-xs (24px-32px)
 * * t-xl (20px-30px)
 * * t-lg (18px-28px)
 * * t-md (16px-24px)
 * * t-sm (14px-20px)
 * * t-xs (12px-18px)
 *
 */
type TypographyElementSize =
  | 'h-2xl'
  | 'h-xl'
  | 'h-lg'
  | 'h-md'
  | 'h-sm'
  | 'h-xs'
  | 't-xl'
  | 't-lg'
  | 't-md'
  | 't-sm'
  | 't-xs'

type TypographyElementWeight = 'normal' | 'medium' | 'semibold' | 'bold'

interface TypographyProps extends JSX.HTMLAttributes<HTMLElement> {
  element: TypographyElement
  size: TypographyElementSize
  weight?: TypographyElementWeight
}

/**
 * Typography component according to the design system
 * @param element - span - p - h1 - h2 - h3 - h4 - h5 - h6
 * @param size - h-2xl - h-xl - h-lg - h-md - h-sm - h-xs - t-xl - t-lg - t-md - t-sm - t-xs
 * @param weight - normal - medium - semibold - bold
 * @returns HTML element
 */
const Typography = (_props: TypographyProps): JSX.Element => {
  const [{ class: typographyClass, element, size, weight }, rest] = splitProps(
    _props,
    ['class', 'element', 'size', 'weight'],
  )

  const sizeClass = createMemo(() => {
    return cn({
      'text-7xl leading-22.5': size === 'h-2xl',
      'text-6xl leading-18': size === 'h-xl',
      'text-5xl leading-15': size === 'h-lg',
      'text-4xl leading-11': size === 'h-md',
      'text-3xl leading-9.5': size === 'h-sm',
      'text-2xl': size === 'h-xs',
      'text-xl leading-7.5': size === 't-xl',
      'text-lg': size === 't-lg',
      'text-base': size === 't-md',
      'text-sm': size === 't-sm',
      'text-xs leading-4.5': size === 't-xs',
    })
  })

  const weightClass = createMemo(() => {
    if (!weight) return 'font-normal'

    return cn({
      'font-normal': weight === 'normal',
      'font-medium': weight === 'medium',
      'font-semibold': weight === 'semibold',
      'font-bold': weight === 'bold',
    })
  })

  return (
    <Dynamic
      class={cn(sizeClass(), weightClass(), typographyClass)}
      component={element}
      {...rest}
    />
  )
}

export default Typography
