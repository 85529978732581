---
import { fade } from 'astro:transitions'

import { HeartIcon, HomeIcon, MenuIcon, BookIcon } from '@/components/solid'

const { pathname: path } = Astro.url
---

<header
  class="md:px-8 px-5 pt-6 flex flex-row items-center justify-between w-full"
>
  <nav class="flex flex-row gap-4 items-center">
    <a
      href="/home"
      class={`${path === '/home' ? 'link-violet' : 'link-white'}`}
    >
      <HomeIcon
        color={path === '/home' ? 'white' : 'black'}
        width={24}
        height={24}
        class="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
      />
    </a>
    <a
      href="/favorites"
      class={`${path === '/favorites' ? 'link-violet' : 'link-white'}`}
    >
      <HeartIcon
        color={path === '/favorites' ? 'white' : 'black'}
        width={24}
        height={24}
        class="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
      />
    </a>
  </nav>

  <div class="w-full h-fit self-center flex items-center justify-center">
    <img
      src="/plain-brand.svg"
      alt="Edsoma brand logo"
      loading="lazy"
      width={224}
      height={100}
      class="w-32 md:w-44 lg:w-56"
      transition:name="brand"
      transition:animate={fade({ duration: '0.5s' })}
    />
  </div>

  <nav class="flex flex-row gap-4 items-center">
    <a
      href="/library"
      class={`${path === '/library' ? 'link-violet' : 'link-white'}`}
    >
      <BookIcon
        color={path === '/library' ? 'white' : 'black'}
        width={24}
        height={24}
        class="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
      />
    </a>

    <button class="link-white" onclick="toogleDrawerMenu()">
      <label for="my-drawer-menu" class="w-fit h-fit"></label>
      <MenuIcon
        color="black"
        width={24}
        height={24}
        class="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
      />
    </button>
  </nav>
</header>

<script is:inline>
  function toogleDrawerMenu() {
    const drawerMenu = document.getElementById('my-drawer-menu')
    drawerMenu?.click()
  }
</script>
