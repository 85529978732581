---
import { Typography } from '@/components'
---

<dialog class="modal" id="countdown-modal">
  <div
    id="progress"
    class="radial-progress bg-white text-tertiary"
    style="--value:70; --size:12.5rem"
    role="progressbar"
  >
    <Typography
      element="span"
      size="h-2xl"
      weight="bold"
      id="progress-text"
      class="text-black"
    />
  </div>
</dialog>
