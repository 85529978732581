import { Typography } from '@/components'
import type { Suggestion } from '@/core/entities'

interface OnboardingSelectedCardProps {
  suggestion?: Suggestion
  title: string
}

const OnboardingSelectedCard = (props: OnboardingSelectedCardProps) => {
  return (
    <div class="flex w-44 flex-col items-center justify-center gap-6 overflow-hidden rounded-xl border border-solid border-violet-50 bg-bg-onboarding-selected-card pb-4 shadow-light-purple h-sm:w-36 h-sm:gap-3 h-sm:pb-2">
      <div class="flex w-full items-center justify-center bg-yellow-60">
        <Typography
          element="span"
          size="t-md"
          weight="semibold"
          class="text-edsm-neutral-100 h-sm:text-sm"
        >
          {props.title}
        </Typography>
      </div>

      <div class="flex h-36 w-32 flex-col items-center justify-center gap-2 overflow-hidden rounded-xl bg-white p-3 h-sm:h-28 h-sm:w-24">
        <img
          src={props.suggestion?.imageUrl}
          alt={props.suggestion?.name}
          class="flex-1 overflow-hidden"
        />

        <Typography
          element="span"
          size="t-md"
          weight="medium"
          class="max-w-full truncate text-edsm-neutral-100 h-sm:text-sm"
        >
          {props.suggestion?.name}
        </Typography>
      </div>
    </div>
  )
}

export default OnboardingSelectedCard
