import { splitProps, type JSX } from 'solid-js'

import { Typography } from '@/components'
import type { Suggestion } from '@/core/entities'
import { cn } from '@/utils'

interface OnboardingSuggestionProps
  extends JSX.InputHTMLAttributes<HTMLInputElement> {
  suggestion: Suggestion
}

const OnboardingSuggestion = (_props: OnboardingSuggestionProps) => {
  const [{ suggestion }, rest] = splitProps(_props, ['suggestion'])

  return (
    <label
      class={cn(
        'flex w-32 cursor-pointer flex-col items-center justify-between gap-2 self-stretch rounded-xl bg-white p-3 transition delay-150 duration-300 h-sm:h-36 h-sm:w-28',
        ' hover:scale-105',
        '[&:has(input:checked)]:outline [&:has(input:checked)]:outline-4 [&:has(input:checked)]:-outline-offset-1 [&:has(input:checked)]:outline-caribbean-green-60',
      )}
      tabIndex={0}
    >
      <Typography
        element="span"
        size="t-md"
        weight="medium"
        class="text-black h-sm:text-sm"
      >
        {suggestion.name}
      </Typography>
      <img
        src={suggestion.imageUrl}
        alt={suggestion.name}
        class="h-22 overflow-hidden"
      />
      <input
        type="radio"
        name="onboarding-suggestion"
        class="peer sr-only"
        {...rest}
      />
      <div
        class={cn(
          'h-6 min-h-6 w-6 rounded-full border border-solid border-caribbean-green-60 transition-all delay-150 duration-150 h-sm:h-4 h-sm:min-h-4 h-sm:w-4',
          'peer-checked:border-6 peer-checked:bg-black h-sm:peer-checked:border-4',
          rest.class,
        )}
      />
    </label>
  )
}

export default OnboardingSuggestion
