import {
  splitProps,
  type Component,
  type ComponentProps,
  createSignal,
  Show,
  createEffect,
  onCleanup,
} from 'solid-js'
import { FiChevronDown } from 'solid-icons/fi'
import { VsClose } from 'solid-icons/vs'

import { cn } from '@/utils'

import Typography from '../Typography'

interface ComboboxProps extends ComponentProps<'div'> {
  hideInput?: boolean
  placeholder?: string
  title?: string
  value?: string

  onSearch?: (value: string) => void
  onValueChange?: (value: string) => void
}

const Combobox: Component<ComboboxProps> = (_props) => {
  const [props, rest] = splitProps(_props, [
    'class',
    'children',
    'hideInput',
    'placeholder',
    'title',
    'value',
    'onSearch',
    'onValueChange',
  ])

  const [containerRef, setContainerRef] = createSignal<HTMLDivElement | null>(
    null,
  )
  const [inputRef, setInputRef] = createSignal<HTMLInputElement | null>(null)
  const [searchValue, setSearchValue] = createSignal<string>('')
  const [showOptions, setShowOptions] = createSignal<boolean>(false)

  const handleClearSearch = (
    event: MouseEvent & {
      currentTarget: SVGSVGElement
      target: Element
    },
  ) => {
    event.stopImmediatePropagation()
    setSearchValue('')
    if (props.onSearch) props.onSearch('')
  }

  const handleClearValue = (
    event: MouseEvent & {
      currentTarget: SVGSVGElement
      target: Element
    },
  ) => {
    event.stopImmediatePropagation()
    if (props.onValueChange) props.onValueChange('')
  }

  const handleShowOptions = () => {
    if (showOptions()) {
      setShowOptions(false)
      return
    }

    setShowOptions(true)
    if (inputRef()) inputRef()?.focus()
  }

  const handleSearch = (
    event: InputEvent & {
      currentTarget: HTMLInputElement
      target: HTMLInputElement
    },
  ) => {
    setSearchValue(event.currentTarget.value)
    if (props.onSearch) props.onSearch(event.currentTarget.value)
  }

  createEffect(() => {
    const container = containerRef()
    if (!container) return

    const handleClickOutside = (event: MouseEvent) => {
      if (container.contains(event.target as Node)) return

      setShowOptions(false)
    }

    document.addEventListener('click', handleClickOutside)

    onCleanup(() => {
      document.removeEventListener('click', handleClickOutside)
    })
  })

  return (
    <div
      class={cn(
        'relative flex w-full cursor-pointer flex-row items-center gap-2 rounded-lg border border-pale-violet-20 bg-pale-violet-10 p-6',
        'focus-within:border-violet-70 focus-within:shadow-element-focus',
        props.class,
      )}
      ref={setContainerRef}
      tabIndex={0}
      onClick={handleShowOptions}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleShowOptions()
        }
      }}
      {...rest}
    >
      <Show when={props.title}>
        <Typography
          element="span"
          size="t-lg"
          class="whitespace-nowrap text-edsm-neutral-70"
        >
          {props.title}
        </Typography>
      </Show>

      <div class="w-full overflow-hidden">
        <Show when={props.placeholder && !props.value}>
          <Typography
            element="span"
            size="t-lg"
            class="whitespace-nowrap text-edsm-neutral-100"
          >
            {props.placeholder}
          </Typography>
        </Show>

        <Show when={props.value}>
          <Typography
            element="span"
            size="t-lg"
            class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-edsm-neutral-100"
          >
            {props.value}
          </Typography>
        </Show>
      </div>

      <Show when={props.value}>
        <VsClose
          aria-label="Clear value"
          aria-controls="clear-value"
          id="clear-value-button"
          size={26}
          class={cn(
            'cursor-pointer text-edsm-neutral-100',
            'hover:rounded-3xl hover:bg-pale-violet-20',
          )}
          onClick={(event) => handleClearValue(event)}
        />
      </Show>

      <FiChevronDown
        aria-label="Show options"
        aria-controls="show-options"
        id="show-options-icon"
        size={24}
        class="text-edsm-neutral-100"
      />

      <Show when={showOptions()}>
        <div
          class="absolute left-0 top-22 z-40 flex w-full animate-combobox-enter flex-col gap-2 rounded-lg border border-pale-violet-20 bg-pale-violet-10 p-6"
          onClick={(event) => event.stopPropagation()}
        >
          <Show when={!props.hideInput}>
            <div
              class={cn(
                'flex w-full flex-row items-center gap-2 rounded-lg border border-pale-violet-20 px-2',
                'focus-within:border-violet-60',
              )}
            >
              <input
                aria-label="Search input"
                aria-controls="search-input"
                id="combobox-search-input"
                ref={setInputRef}
                class="w-full border-none bg-transparent p-4 outline-none"
                type="text"
                value={searchValue()}
                onInput={(event) => handleSearch(event)}
                onClick={(event) => event.stopPropagation()}
              />
              <Show when={searchValue()}>
                <VsClose
                  aria-label="Clear search"
                  aria-controls="clear-search"
                  id="clear-search"
                  size={26}
                  class={cn(
                    'cursor-pointer text-edsm-neutral-100',
                    'hover:rounded-3xl hover:bg-pale-violet-20',
                  )}
                  onClick={(event) => handleClearSearch(event)}
                />
              </Show>
            </div>
          </Show>

          <div class="flex max-h-80 flex-col gap-2 overflow-y-auto py-4">
            {props.children}
          </div>
        </div>
      </Show>
    </div>
  )
}

export default Combobox
