import { createSignal, For, onMount } from 'solid-js'
import { useStore } from '@nanostores/solid'

import { Typography } from '@/components'
import type { Suggestion } from '@/core/entities'
import {
  getSuggestionsByCategory,
  getStudentOnboarding,
  loadOnboardingSuggestions,
  onboardingSuggestions,
  setStudentSuggestions,
} from '@/store'
import OnboardingSuggestion from './OnboardingSuggestion'
interface OnboardingSelectionProps {
  step: 'animal' | 'book' | 'hobby'
  title: string
}

const OnboardingSelection = (props: OnboardingSelectionProps) => {
  const storedSuggestions = useStore(onboardingSuggestions)

  const [loading, setLoading] = createSignal<boolean>(false)
  const [selectedSuggestionId, setSelectedSuggestionId] =
    createSignal<Suggestion['id']>()

  const handleLoadingOnboardingSuggestions = async () => {
    try {
      setLoading(true)
      await loadOnboardingSuggestions()
    } finally {
      setLoading(false)
    }
  }

  onMount(() => {
    if (!storedSuggestions() || !storedSuggestions().suggestions?.length) {
      handleLoadingOnboardingSuggestions()
    }
    const studentOnboarding = getStudentOnboarding()
    if (studentOnboarding && studentOnboarding[props.step][0]) {
      setSelectedSuggestionId(studentOnboarding[props.step][0].id)
    }
  })

  const handleSelect = (
    event: Event & {
      currentTarget: HTMLInputElement
      target: HTMLInputElement
    },
  ) => {
    const selectedSuggestion = getSuggestionsByCategory(props.step).find(
      (suggestion) => {
        return String(suggestion.id) === event.currentTarget?.value
      },
    )

    if (!selectedSuggestion) return
    setSelectedSuggestionId(selectedSuggestion.id)
    setStudentSuggestions({
      step: props.step,
      suggestion: selectedSuggestion,
    })
  }

  return (
    <div class="flex flex-col gap-6">
      <Typography
        element="h2"
        size="h-md"
        weight="bold"
        class="text-center text-white lg:text-start h-sm:text-2xl"
      >
        {props.title}
      </Typography>
      <div class="flex w-full flex-row flex-wrap content-stretch justify-center gap-4 px-0 lg:px-8">
        {loading() ? (
          // TODO: add a loading here
          ''
        ) : (
          <For each={getSuggestionsByCategory(props.step)}>
            {(suggestion) => (
              <OnboardingSuggestion
                suggestion={suggestion}
                onchange={(value) => handleSelect(value)}
                value={suggestion.id}
                checked={selectedSuggestionId() === suggestion.id}
              />
            )}
          </For>
        )}
      </div>
    </div>
  )
}

export default OnboardingSelection
