import {
  splitProps,
  type Component,
  type ComponentProps,
  createSignal,
  Show,
  createEffect,
  onCleanup,
  For,
} from 'solid-js'
import { FiChevronDown } from 'solid-icons/fi'
import { VsClose } from 'solid-icons/vs'

import { cn } from '@/utils'

import { CheckIcon, Typography } from '@/components'

interface CheckboxGroupProps extends ComponentProps<'div'> {
  placeholder?: string
  title?: string
  options: { label: string; value: string }[]
  selectedValues: string[]
  showedValues: string[]
  onValueChange?: (values: unknown[]) => void
}

const CheckboxGroup: Component<CheckboxGroupProps> = (_props) => {
  const [props, rest] = splitProps(_props, [
    'class',
    'title',
    'options',
    'selectedValues',
    'onValueChange',
    'placeholder',
    'showedValues',
  ])

  const [containerRef, setContainerRef] = createSignal<HTMLDivElement | null>(
    null,
  )
  const [showOptions, setShowOptions] = createSignal<boolean>(false)

  const handleCheckboxChange = (event: Event) => {
    const { value, checked } = event.currentTarget as HTMLInputElement
    let updatedValues = [...props.selectedValues]

    if (checked) {
      updatedValues.push(value)
    } else {
      updatedValues = updatedValues.filter((v) => v !== value)
    }

    if (props.onValueChange) {
      props.onValueChange(updatedValues)
    }
  }

  const handleClearAll = (
    event: MouseEvent & {
      currentTarget: SVGSVGElement
      target: Element
    },
  ) => {
    event.stopImmediatePropagation()
    if (props.onValueChange) props.onValueChange([])
  }

  const handleShowOptions = () => {
    setShowOptions(!showOptions())
  }

  createEffect(() => {
    const container = containerRef()
    if (!container) return

    const handleClickOutside = (event: MouseEvent) => {
      if (container.contains(event.target as Node)) return
      setShowOptions(false)
    }

    document.addEventListener('click', handleClickOutside)

    onCleanup(() => {
      document.removeEventListener('click', handleClickOutside)
    })
  })

  return (
    <div
      class={cn(
        'relative flex w-full cursor-pointer flex-row items-center gap-2 rounded-lg border border-pale-violet-20 bg-pale-violet-10 p-6',
        'focus-within:border-violet-70 focus-within:shadow-element-focus',
        props.class,
      )}
      ref={setContainerRef}
      tabIndex={0}
      onClick={handleShowOptions}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleShowOptions()
        }
      }}
      {...rest}
    >
      <Show when={props.title}>
        <Typography
          element="span"
          size="t-lg"
          class="whitespace-nowrap text-edsm-neutral-70"
        >
          {props.title}
        </Typography>
      </Show>

      <div class="w-full overflow-hidden">
        <Show when={props.placeholder && props.selectedValues.length === 0}>
          <Typography
            element="span"
            size="t-lg"
            class="whitespace-nowrap text-edsm-neutral-100"
          >
            {props.placeholder}
          </Typography>
        </Show>

        <Show when={props.showedValues.length > 0}>
          <Typography
            element="span"
            size="t-lg"
            class="whitespace-nowrap text-edsm-neutral-100"
          >
            {props.showedValues.join(', ')}
          </Typography>
        </Show>
      </div>

      <Show when={props.selectedValues.length > 0}>
        <VsClose
          aria-label="Clear selected values"
          size={26}
          class={cn(
            'cursor-pointer text-edsm-neutral-100',
            'hover:rounded-3xl hover:bg-pale-violet-20',
          )}
          onClick={(event) => handleClearAll(event)}
        />
      </Show>

      <FiChevronDown
        aria-label="Show options"
        size={24}
        class="text-edsm-neutral-100"
      />

      <Show when={showOptions()}>
        <div
          class="absolute left-0 top-22 z-40 flex w-full animate-combobox-enter flex-col gap-2 rounded-lg border border-pale-violet-20 bg-pale-violet-10"
          onClick={(event) => event.stopPropagation()}
        >
          <div class="flex max-h-80 flex-col gap-2 overflow-y-auto p-4">
            <For each={props.options}>
              {(option) => (
                <label
                  class={cn(
                    'relative flex h-[72px] w-full cursor-pointer flex-row items-center gap-3 rounded-xl border-2 border-violet-60 bg-pale-violet-10 p-6 transition delay-150 duration-300',
                    ' hover:border-violet-80 hover:shadow-sm',
                    '[&:has(input:checked)]:bg-pale-violet-20 [&:has(input:checked)]:outline [&:has(input:checked)]:outline-4 [&:has(input:checked)]:-outline-offset-1 [&:has(input:checked)]:outline-violet-60',
                  )}
                  tabIndex={0}
                >
                  <input
                    type="checkbox"
                    name="checkbox-group"
                    class="peer sr-only"
                    value={option.value}
                    checked={props.selectedValues.includes(option.value)}
                    onChange={handleCheckboxChange}
                  />

                  <div
                    class={cn(
                      'h-6 min-h-6 w-6 rounded-sm border border-solid border-violet-60 transition-all delay-150 duration-150 h-sm:h-4 h-sm:min-h-4 h-sm:w-4',
                      'peer-checked:border-6 peer-checked:bg-violet-60 h-sm:peer-checked:border-3',
                    )}
                  />
                  <div class="invisible absolute left-[27px] top-[25px] transition-all delay-200 duration-200 peer-checked:visible ">
                    <CheckIcon width={20} height={20} color="white" />
                  </div>

                  <Typography
                    element="span"
                    size="t-md"
                    weight="medium"
                    class="text-black h-sm:text-sm"
                  >
                    {option.label}
                  </Typography>
                </label>
              )}
            </For>
          </div>
        </div>
      </Show>
    </div>
  )
}

export default CheckboxGroup
