/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  For,
  Show,
  createEffect,
  createMemo,
  createSignal,
  onMount,
} from 'solid-js'
import { FiChevronRight } from 'solid-icons/fi'

import { Typography } from '@/components'
import { cn } from '@/utils'

interface BadgeInputProps {
  class?: string
  id: string
  items: any[]
  itemTitleKey?: string
  placeholder?: string
  onClickContainer: () => void
  onClickRemoveItem: (item: any) => void
}

const BADGE_CONTAINER_GAP = 8
const HIDDEN_ITEMS_BADGE_SIZE = 48

const BadgeInput = (props: BadgeInputProps) => {
  const [hiddenItems, setHiddenItems] = createSignal<number>(0)
  const [container, setContainer] = createSignal<HTMLDivElement | null>(null)
  const [hiddenItemsBadge, setHiddenItemsBadge] =
    createSignal<HTMLDivElement | null>(null)

  const itemTitle = createMemo(() => {
    return props.itemTitleKey || 'title'
  })
  const visibleBadgeContainerId = createMemo(() => {
    return [props.id, 'visible-badge-container'].join('-')
  })
  const hiddenItemsBadgeId = createMemo(() => {
    return [props.id, 'hidden-items-badge'].join('-')
  })

  const handleClickContainer = () => {
    props.onClickContainer()
  }

  const handleRemoveItem = (
    event: MouseEvent & {
      currentTarget: HTMLElement
      target: Element
    },
    item: any,
  ) => {
    event.stopPropagation()
    props.onClickRemoveItem(item)
  }

  const handleBadge = () => {
    const containerEl = container()
    const hiddenItemsBadgeEl = hiddenItemsBadge()
    if (!containerEl || !hiddenItemsBadgeEl) return

    const containerElChildren = containerEl.children
    const containerChildrenWidth = Array.from(containerElChildren).reduce(
      (acc, curr, index) => {
        return (
          acc +
          (index ? curr.clientWidth + BADGE_CONTAINER_GAP : curr.clientWidth)
        )
      },
      hiddenItemsBadgeEl ? -HIDDEN_ITEMS_BADGE_SIZE - BADGE_CONTAINER_GAP : 0,
    )

    const containerWidth = containerEl.offsetWidth

    hiddenItemsBadgeEl?.remove()
    if (containerChildrenWidth >= containerWidth) {
      const result = Array.from(containerElChildren).reduce<{
        hiddenCount: number
        childrenWidth: number
        lastVisibleEl: Element | null
      }>(
        (acc, curr, index) => {
          const childWidth = index
            ? curr.clientWidth + BADGE_CONTAINER_GAP
            : curr.clientWidth
          const isNumberBadge = curr.id === hiddenItemsBadgeEl?.id

          if (
            containerWidth - HIDDEN_ITEMS_BADGE_SIZE >
              acc.childrenWidth + childWidth ||
            isNumberBadge
          ) {
            curr.classList.remove('invisible')
            return {
              hiddenCount: acc.hiddenCount,
              childrenWidth: acc.childrenWidth + childWidth,
              lastVisibleEl: isNumberBadge ? acc.lastVisibleEl : curr,
            }
          }

          curr.classList.add('invisible')
          return {
            hiddenCount: acc.hiddenCount + 1,
            childrenWidth: acc.childrenWidth + childWidth,
            lastVisibleEl: acc.lastVisibleEl,
          }
        },
        { hiddenCount: 0, childrenWidth: 0, lastVisibleEl: null },
      )

      if (result.hiddenCount && result.lastVisibleEl) {
        result.lastVisibleEl.insertAdjacentElement(
          'afterend',
          hiddenItemsBadgeEl,
        )
        hiddenItemsBadgeEl.classList.remove('invisible')
      } else {
        hiddenItemsBadgeEl.classList.add('invisible')
      }

      setHiddenItems(result.hiddenCount)
    } else {
      for (const currentEl of Array.from(containerElChildren)) {
        currentEl.classList.remove('invisible')
      }
    }
  }

  createEffect((prev) => {
    if (prev === props.items.length) return

    handleBadge()
    return props.items.length
  }, 0)

  onMount(() => {
    const containerEl = document.getElementById(
      visibleBadgeContainerId(),
    ) as HTMLDivElement
    setContainer(containerEl)
    const hiddenItemsBadgeEl = document.getElementById(
      hiddenItemsBadgeId(),
    ) as HTMLDivElement
    setHiddenItemsBadge(hiddenItemsBadgeEl)

    handleBadge()
    containerEl.classList.remove('invisible')

    window.addEventListener('resize', () => {
      handleBadge()
    })
  })

  return (
    <div
      id={props.id}
      onClick={handleClickContainer}
      class={cn(
        'flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border-2 border-violet-60 bg-pale-violet-10 p-6',
        props.class,
      )}
    >
      <div class="whitespace-nowrap">
        <Typography
          element="span"
          size="t-lg"
          class="mr-2 text-edsm-neutral-70"
        >
          Show:
        </Typography>

        <Show when={!props.items.length}>
          <Typography element="span" size="t-lg" class="text-edsm-neutral-100">
            {props.placeholder || ''}
          </Typography>
        </Show>
      </div>

      <div
        id={visibleBadgeContainerId()}
        class="invisible flex h-11 w-full gap-2 overflow-hidden"
      >
        <For each={props.items}>
          {(item) => (
            <div class="flex flex-row items-center rounded-full bg-violet-60 pl-2">
              <Typography
                element="span"
                size="t-lg"
                class="whitespace-nowrap text-white"
              >
                {item[itemTitle()]}
              </Typography>
              <button
                class="h-11 w-8 rounded-full hover:bg-violet-80"
                onClick={(event) => handleRemoveItem(event, item)}
              >
                <Typography
                  element="span"
                  size="t-md"
                  weight="bold"
                  class="text-white"
                >
                  x
                </Typography>
              </button>
            </div>
          )}
        </For>

        <div
          id={hiddenItemsBadgeId()}
          class="invisible h-11 w-12 min-w-12 rounded-full bg-violet-60 p-2"
        >
          <Typography element="span" size="t-lg" class="text-white">
            {`+${hiddenItems()}`}
          </Typography>
        </div>
      </div>

      <FiChevronRight size={24} class="text-edsm-neutral-100" />
    </div>
  )
}

export default BadgeInput
