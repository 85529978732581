import { createEffect, createSignal, onMount, type Setter } from 'solid-js'

interface YoutubeVideoProps {
  iframeId: string
  videoId: string
  autoplay?: boolean
  setYoutubePlayer?: Setter<YT.Player | null>
}

const YoutubeVideo = (props: YoutubeVideoProps) => {
  const [youtubeAPIReady, setYoutubeAPIReady] = createSignal<boolean>(false)

  function onPlayerReady(event: YT.PlayerEvent) {
    if (props.autoplay) event.target.playVideo()
  }

  function onYouTubeIframeAPIReady() {
    const youtubePlayer = new window.YT.Player(props.iframeId, {
      height: '360',
      width: '640',
      videoId: props.videoId,
      playerVars: {
        autoplay: props.autoplay ? 1 : 0,
        rel: 0,
        showinfo: 0,
        disablekb: 1,
      },
      events: {
        onReady: onPlayerReady,
      },
    })
    if (props.setYoutubePlayer) props.setYoutubePlayer(youtubePlayer)
  }

  onMount(() => {
    const youtubeAPICheckInterval = setInterval(() => {
      if (typeof YT !== 'undefined' && typeof YT.Player !== 'undefined') {
        setYoutubeAPIReady(true)
        clearInterval(youtubeAPICheckInterval)
      }
    }, 50)
  })

  createEffect(() => {
    if (youtubeAPIReady()) onYouTubeIframeAPIReady()
  })

  return <div id={props.iframeId} class="rounded-xl" />
}

export default YoutubeVideo
