import { createSignal } from 'solid-js'
import { makePersisted } from '@solid-primitives/storage'

import type { BookCategory } from '@/core/entities'
import type { SessionStorage } from '@/store/session'

interface SelectedBookCategoryStore extends SessionStorage<BookCategory[]> {
  clearSelectedBookCategory: () => void
  isBookCategorySelected: (category: BookCategory) => boolean
  removeSelectedBookCategory: (category: BookCategory) => void
}

const SELECTED_BOOK_CATEGORY_KEY = 'selectedBookCategory'
let selectedBookCategoryStore: SelectedBookCategoryStore | undefined = undefined

export const getSelectedBookCategoryStore = (): SelectedBookCategoryStore => {
  if (selectedBookCategoryStore) return selectedBookCategoryStore

  const [selectedBookCategory, setSelectedBookCategory] = makePersisted(
    createSignal<BookCategory[]>([]),
    {
      storage: sessionStorage,
      name: SELECTED_BOOK_CATEGORY_KEY,
    },
  )

  const clearSelectedBookCategory = (): void => {
    setSelectedBookCategory([])
  }

  const isBookCategorySelected = (category: BookCategory): boolean => {
    return selectedBookCategory().some((item) => item.id === category.id)
  }

  const removeSelectedBookCategory = (category: BookCategory): void => {
    setSelectedBookCategory((prev) =>
      prev.filter((item) => item.id !== category.id),
    )
  }

  selectedBookCategoryStore = {
    value: selectedBookCategory,
    setValue: setSelectedBookCategory,
    clearSelectedBookCategory,
    isBookCategorySelected,
    removeSelectedBookCategory,
  }

  return selectedBookCategoryStore
}
