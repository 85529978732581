import {
  type Component,
  type ComponentProps,
  splitProps,
  createSignal,
  Show,
  createEffect,
} from 'solid-js'
import { AiOutlineSearch } from 'solid-icons/ai'
import { VsClose } from 'solid-icons/vs'

import { Typography } from '@/components'
import { cn } from '@/utils'

interface SearchInputProps extends ComponentProps<'input'> {
  containerClass?: string
  onSearch?: (value: string) => void
}

const SearchInput: Component<SearchInputProps> = (_props) => {
  const [props, rest] = splitProps(_props, [
    'class',
    'containerClass',
    'onSearch',
    'value',
  ])

  const [inputRef, setInputRef] = createSignal<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = createSignal<string>('')

  const handleClearInput = () => {
    setInputValue('')
    handleSearch()
  }

  const handleFocusInput = () => {
    inputRef()?.focus()
    handleSearch()
  }

  const handleSearch = () => {
    if (props.onSearch && inputValue() !== props.value) {
      console.log('inputValue', inputValue())
      props.onSearch(inputValue())
    }
  }

  createEffect(() => {
    setInputValue(String(props.value || ''))
  })

  return (
    <div
      class={cn(
        'flex w-full items-center justify-between gap-2 rounded-lg border border-pale-violet-20 bg-pale-violet-10 px-6 transition delay-150 duration-150',
        'focus-within:border-violet-70 focus-within:shadow-element-focus',
        props.containerClass,
      )}
    >
      <AiOutlineSearch
        size={26}
        class="text-edsm-neutral-100"
        onClick={handleFocusInput}
      />

      <input
        aria-label="Search input"
        type="text"
        ref={setInputRef}
        class={cn(
          'w-full bg-transparent p-6 text-lg text-edsm-neutral-80 outline-none',
          props.class,
        )}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            handleSearch()
          }
        }}
        value={inputValue()}
        onInput={(event) => setInputValue(event.currentTarget.value)}
        {...rest}
      />

      <Show when={inputValue()}>
        <VsClose
          size={26}
          class={cn(
            'cursor-pointer text-edsm-neutral-100',
            'hover:rounded-3xl hover:bg-pale-violet-20',
          )}
          onClick={handleClearInput}
        />
      </Show>

      <button
        class={cn('h-11 rounded-full bg-violet-60 px-4', 'hover:bg-violet-80')}
        onClick={handleSearch}
      >
        <Typography element="span" size="t-md" weight="bold" class="text-white">
          Search
        </Typography>
      </button>
    </div>
  )
}

export default SearchInput
