---
import { Image } from 'astro:assets'

import { Typography } from '@/components'
import type { Student } from '@/core/entities'
import { cn, getAgeByBirthday } from '@/utils'

interface Props {
  cardClass?: string
}

const { cardClass } = Astro.props

const userCookie = Astro.cookies.get('user')
const user = userCookie?.value
  ? (JSON.parse(userCookie.value) as Student)
  : null

const userAge = user ? getAgeByBirthday(user.birthDate) : null
---

<div
  class={cn(
    'flex sm:flex-row flex-col gap-6 px-6 py-4 rounded-2xl items-center bg-bg-student-card w-full sm:h-36 border border-solid border-violet-50 shadow-light-purple landscape:h-sm:py-2 landscape:h-sm:h-auto',
    cardClass,
  )}
>
  <Image
    class="rounded-full border-4 border-solid border-tertiary landscape:h-sm:w-14 landscape:h-sm:h-14 w-[104px] h-[104px]"
    src={user?.imageUrl || ''}
    alt={user?.name || ''}
    width={104}
    height={104}
  />

  <div class="flex flex-col gap-1 landscape:h-sm:flex-row landscape:h-sm:gap-4">
    <Typography element="h2" size="h-xs" weight="bold" class="text-white">
      {user?.name.replace('name', '')}
    </Typography>
    <div class="inline-flex gap-3 items-center text-white">
      {
        userAge ? (
          <>
            <Typography
              element="span"
              size="t-md"
              weight="semibold"
              class="whitespace-nowrap"
            >
              {['Age', userAge].join(' ')}
            </Typography>
            <div class="rounded-full border-3 border-solid border-white" />
          </>
        ) : (
          ''
        )
      }

      <Typography
        element="span"
        size="t-md"
        weight="medium"
        class="whitespace-nowrap"
      >
        {[user?.grade, 'Grade'].join(' ')}
      </Typography>
    </div>
  </div>
</div>
