import { atom } from 'nanostores'

import { type StudentInstruction, StudentInstructions } from '@/core/entities'
import {
  childInstructionFindOrCreate,
  childInstructionUpdate,
} from '@/core/infra/graphql'

export const onboardingVideoStore = atom<StudentInstruction | undefined>(
  undefined,
)
export const loadingOnboardingVideoStore = atom<boolean>(false)

export const loadOnboardingVideo = async (childId: number): Promise<void> => {
  if (typeof onboardingVideoStore.get() !== 'undefined' || !childId) return

  try {
    loadingOnboardingVideoStore.set(true)
    const response = await childInstructionFindOrCreate(
      childId,
      StudentInstructions.ONBOARDING_VIDEO,
    )
    onboardingVideoStore.set(response.childInstructionFindOrCreate)
  } catch (error) {
    console.error(error)
  } finally {
    loadingOnboardingVideoStore.set(false)
  }
}

export const completeOnboardingVideo = async (): Promise<void> => {
  const storeValue = onboardingVideoStore.get()
  if (typeof storeValue === 'undefined') return

  try {
    const response = await childInstructionUpdate(storeValue.id, 'COMPLETED')
    if (response.childInstructionUpdate.success)
      onboardingVideoStore.set({
        ...storeValue,
        status: 'COMPLETED',
      })
  } catch (error) {
    console.error(error)
  }
}
