import { For, Show, createMemo, createSignal, onMount } from 'solid-js'
import { useStore } from '@nanostores/solid'

import { Typography, BookCategoryItem, CrossIcon } from '@/components'
import type { BookCategory } from '@/core/entities'
import {
  activeCategories,
  loadBookCategories,
  loadingBookCategories,
} from '@/store'
import { getSelectedBookCategoryStore } from '@/store/session'
import { cn, handleDrawerScroll } from '@/utils'
import { trackButtonClick } from '@/utils/analytics'

const closeDrawer = () => {
  if (!document) return

  const drawer = document.getElementById(
    'my-drawer-category',
  ) as HTMLInputElement

  if (!drawer) return
  drawer.click()
}

const BookCategoryDrawer = () => {
  const categories = useStore(activeCategories)
  const loading = useStore(loadingBookCategories)

  const {
    value: selectedCategories,
    setValue: setSelectedCategories,
    clearSelectedBookCategory,
  } = getSelectedBookCategoryStore()

  const [selectedItems, setSelectedItems] = createSignal<BookCategory[]>([])

  const confirmButtonText = createMemo(() => {
    if (!selectedItems().length) return 'Show All Books'

    const totalBooks = selectedItems().reduce((acc, item) => {
      return acc + item.totalBooks
    }, 0)

    return totalBooks
      ? ['Show', totalBooks, 'books'].join(' ')
      : 'Show All Books'
  })

  const toggleItem = (item: BookCategory) => {
    if (selectedItems().includes(item)) {
      setSelectedItems(selectedItems().filter((i) => i !== item))
    } else {
      setSelectedItems([...selectedItems(), item])
    }
  }

  const confirmSelection = () => {
    setSelectedCategories(selectedItems())
    closeDrawer()
  }

  const clearSelection = () => {
    clearSelectedBookCategory()
    setSelectedItems([])
    closeDrawer()
  }

  const handleChange = (
    e: Event & {
      currentTarget: HTMLInputElement
      target: HTMLInputElement
    },
  ) => {
    if (e.currentTarget.checked) {
      setSelectedItems(selectedCategories())
      trackButtonClick('Book Category Drawer', {
        category: selectedCategories().map((item) => item.name),
      })
    }
  }

  onMount(() => {
    loadBookCategories()
    handleDrawerScroll('my-drawer-category')
  })

  return (
    <div class="drawer drawer-end">
      <input
        id="my-drawer-category"
        type="checkbox"
        class="drawer-toggle"
        onChange={(event) => handleChange(event)}
      />
      <div class="drawer-content" />
      <div class="drawer-side z-30">
        <label
          for="my-drawer-category"
          aria-label="close sidebar"
          class="drawer-overlay checked:bg-edsm-neutral-100 checked:opacity-50"
        />
        <div
          class={cn(
            'menu absolute flex h-screen w-[100%] flex-row justify-between border border-solid border-violet-50 bg-white bg-gradient-to-b from-gradient-color-violet-from to-gradient-color-violet-to p-0 text-base-content',
            'md:w-auto',
          )}
        >
          <div class="flex h-[calc(100vh-180px)]  w-full flex-col items-center justify-center md:h-[calc(100vh-116px)]">
            <div
              class={cn('flex w-full px-6 py-6', 'md:px-8 landscape:h-md:pb-0')}
            >
              <button
                onClick={closeDrawer}
                class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-white"
              >
                <CrossIcon width={20} height={20} color="white" />
              </button>
            </div>

            <Typography
              element="span"
              size="t-lg"
              weight="normal"
              class={cn('my-4 text-white', 'landscape:h-md:my-2')}
            >
              {selectedItems().length
                ? [selectedItems().length, 'selected'].join(' ')
                : 'Select book categories'}
            </Typography>

            <div
              class={cn(
                'grid w-full grid-cols-2 gap-y-10 overflow-y-auto overflow-x-hidden py-8 pb-8',
                'lg:grid-cols-4',
              )}
            >
              <Show when={!loading()}>
                <For each={categories()}>
                  {(category) => (
                    <BookCategoryItem
                      category={category}
                      isSelected={selectedItems().includes(category)}
                      onSelect={() => toggleItem(category)}
                    />
                  )}
                </For>
              </Show>
            </div>
          </div>
          <div class="flex w-full flex-col items-center justify-center gap-6 bg-violet-80 p-8 pt-6 shadow-footer md:flex-row">
            <button
              onClick={clearSelection}
              class="btn-secondary-edsoma w-full max-w-80 border-white bg-transparent text-white hover:border-violet-60 hover:bg-violet-10 hover:text-violet-60"
            >
              Clear All
            </button>

            <button
              onClick={confirmSelection}
              class="btn-primary-edsoma w-full max-w-80"
            >
              <Typography
                element="p"
                size="t-lg"
                weight="bold"
                class="m-auto w-40"
              >
                {confirmButtonText()}
              </Typography>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookCategoryDrawer
