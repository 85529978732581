import { createEffect, createSignal, onMount, Show } from 'solid-js'
import { useStore } from '@nanostores/solid'

import { CrossIcon, YoutubeVideo } from '@/components'
import {
  completeBookVideo,
  loadBookVideo,
  bookVideoStore,
  bookVideoShowStore,
  hideBookVideo,
} from '@/store'
import { pauseVideoSafely, getYoutubeVideoIDFromURL } from '@/utils'

interface VideoInstructionDialogProps {
  childId: number
}

const VideoInstructionDialog = (props: VideoInstructionDialogProps) => {
  const bookVideo = useStore(bookVideoStore)
  const bookVideoShow = useStore(bookVideoShowStore)

  const [youtubeVideoPlayer, setYoutubeVideoPlayer] =
    createSignal<YT.Player | null>(null)
  const [modalRef, setModalRef] = createSignal<HTMLDialogElement>()
  const [videoID, setVideoID] = createSignal<string>('')

  onMount(async () => {
    await loadBookVideo(props.childId)
  })

  createEffect(() => {
    const bookVideoInstruction = bookVideo()
    const bookVideoShowValue = bookVideoShow()

    if (!bookVideoInstruction) return

    if (bookVideoInstruction.status === 'PENDING') {
      const videoMatchedID = getYoutubeVideoIDFromURL(
        bookVideoInstruction.instruction.contentSource,
      )

      if (videoMatchedID && bookVideoShowValue) {
        setVideoID(videoMatchedID)
        const modal = modalRef()
        if (modal) {
          modal.showModal()

          const closeHandler = () => {
            const interval = setInterval(() => {
              if (pauseVideoSafely(youtubeVideoPlayer())) {
                clearInterval(interval)
              }
              hideBookVideo()
            }, 500)
          }

          modal.addEventListener('close', closeHandler)
          completeBookVideo()
        }
      }
    }
  })

  return (
    <dialog id="modal" class="modal" ref={(el) => setModalRef(el)}>
      <div class="modal-box w-10/12 max-w-3xl">
        <form method="dialog">
          <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
            <CrossIcon width={20} height={20} color="#7C4BE2" />
          </button>
        </form>

        <div class="flex w-full items-center justify-center">
          <Show when={videoID()}>
            <YoutubeVideo
              iframeId="home-instructions"
              videoId={videoID()}
              setYoutubePlayer={setYoutubeVideoPlayer}
              autoplay
            />
          </Show>
        </div>
      </div>
    </dialog>
  )
}

export default VideoInstructionDialog
