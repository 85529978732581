import { gql } from 'graphql-request'
import { graphqlMethod } from '../infra'

type Language = {
  id: number
  locale: string
  name: string
}

type ListLanguageResponse = {
  languageList: Language[]
}

export const getLanguages = async ({
  locale,
  name,
}: {
  locale?: string
  name?: string
}): Promise<ListLanguageResponse> => {
  const listLanguageSession = sessionStorage.getItem('@eds:listLanguage')

  if (listLanguageSession) {
    return JSON.parse(listLanguageSession)
  }

  try {
    const query = gql`
      query LanguageList($data: LanguageListInput!) {
        languageList(data: $data) {
          createdAt
          id
          locale
          name
        }
      }
    `

    const queryVariables: {
      data: {
        locale?: string
        name?: string
      }
    } = {
      data: {
        locale,
        name,
      },
    }

    const response = await graphqlMethod<ListLanguageResponse>(
      query,
      {},
      queryVariables,
    )

    sessionStorage.setItem('@eds:listLanguage', JSON.stringify(response))

    return response
  } catch (error) {
    throw new Error(error as string)
  }
}
