import { For, createResource, Show, createMemo } from 'solid-js'
import { BookCardItem, LoadingBooks, Typography } from '@/components'
import { childBooks } from '@/core/infra/graphql'
import type { Book, ClassAssignment, ReadingResult } from '@/core/entities'

export type FavoriteBook = Pick<
  Book,
  'id' | 'name' | 'author' | 'childBook' | 'imageUrl' | 'webBookUrl'
>

type ChildBook = {
  id?: number
  name?: string
  author?: string
  imageUrl?: string
  webBookUrl?: string
  childId?: number
  bookId?: number
  isFavorite?: boolean
  isAssigned?: boolean
  progress?: number
  book?: FavoriteBook
  classAssignment?: ClassAssignment
  readingResult?: ReadingResult
}

type fetchBooksResponse = {
  favoriteBooks: FavoriteBook[]
}

async function fetchFavoriteBooks(
  childId: number,
): Promise<fetchBooksResponse> {
  const favoriteBooks = await childBooks<ChildBook>({
    fields: ['book', 'bookId', 'isFavorite', 'progress'],
    variables: { childId, favorite: true, filterType: 'AND' },
    options: {
      book: ['id', 'name', 'author', 'imageUrl', 'webBookUrl'],
    },
  })

  return {
    favoriteBooks: favoriteBooks.childBooks.map(
      ({ book, isFavorite, progress }) =>
        ({
          id: book?.id,
          name: book?.name,
          author: book?.author,
          imageUrl: book?.imageUrl,
          ...book,
          childBook: {
            isFavorite: isFavorite,
            progress: progress,
          },
        }) as FavoriteBook,
    ),
  }
}

const FavoritesBooksGrid = (props: { childId: number }) => {
  const [data, { mutate }] = createResource<fetchBooksResponse>(() =>
    fetchFavoriteBooks(props.childId),
  )

  const handleFavoriteAction = (bookId: number) => {
    mutate((prev) => {
      if (!prev || !prev.favoriteBooks) return prev

      return {
        ...prev,
        favoriteBooks: prev.favoriteBooks.map((book) => {
          if (book.id === bookId) {
            return {
              ...book,
              childBook: {
                ...book.childBook,
                isFavorite: !book.childBook.isFavorite,
              },
            }
          }

          return book
        }),
      }
    })
  }

  const favoriteBooks = createMemo(() => {
    if (!data()?.favoriteBooks?.length) return []

    return data()?.favoriteBooks.filter((item) => item.childBook.isFavorite)
  })

  return (
    <div class="mt-12 flex w-full flex-col items-center justify-center">
      <Show when={data()} fallback={<LoadingBooks />}>
        <Show when={favoriteBooks()?.length}>
          <Typography
            element="h1"
            size="h-md"
            weight="bold"
            class="mb-6 text-edsm-neutral-100"
          >
            Your favorites
          </Typography>
        </Show>

        <div class="flex w-full flex-row flex-wrap content-stretch items-center justify-center justify-items-center gap-6">
          <For each={favoriteBooks()}>
            {(book) => (
              <BookCardItem
                showFavoriteButton
                handleFavorite={handleFavoriteAction}
                booksDetail={book as Book}
              />
            )}
          </For>
        </div>

        <Show when={!favoriteBooks()?.length}>
          <div class="flex w-full flex-col items-center justify-center gap-6">
            <img
              srcSet="/crying-unicorn.svg"
              loading="lazy"
              alt="Crying unicorn"
            />
            <Typography
              element="span"
              size="t-xl"
              class="text-edsm-neutral-100"
            >
              No favorites yet
            </Typography>
          </div>
        </Show>
      </Show>
    </div>
  )
}

export default FavoritesBooksGrid
