import { splitProps, type JSX } from 'solid-js'

interface IconProps extends JSX.SvgSVGAttributes<SVGSVGElement> {
  color: string
  fillColor?: string
}

const Check = (_props: IconProps) => {
  const [{ height = '100%', width = '100%', fillColor = 'none', color }, rest] =
    splitProps(_props, ['height', 'width', 'color', 'fillColor'])

  return (
    <svg
      {...rest}
      height={height}
      width={width}
      viewBox={`0 0 ${height} ${width}`}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3803_1545)">
        <path
          d="M14.6667 7.38674V8.00007C14.6658 9.43769 14.2003 10.8365 13.3395 11.988C12.4788 13.1394 11.2688 13.9817 9.89022 14.3893C8.5116 14.797 7.03815 14.748 5.68963 14.2498C4.3411 13.7516 3.18975 12.8308 2.40729 11.6248C1.62482 10.4188 1.25317 8.99211 1.34776 7.55761C1.44235 6.12312 1.99812 4.75762 2.93217 3.66479C3.86621 2.57195 5.1285 1.81033 6.53077 1.4935C7.93304 1.17668 9.40016 1.32163 10.7133 1.90674M14.6667 2.66674L7.99998 9.34007L5.99998 7.34007"
          stroke={color}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3803_1545">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Check
