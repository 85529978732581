import { createSignal } from 'solid-js'
import { makePersisted } from '@solid-primitives/storage'

import type { AgeRange } from '@/core/entities'
import type { SessionStorage } from '@/store/session'

interface SelectedAgeRangeStore extends SessionStorage<AgeRange[]> {
  isAgeRangeSelected: (ageRange: AgeRange) => boolean
  removeSelectedAgeRange: (ageRange: AgeRange) => void
  clearSelectedAgeRanges: () => void
  ageRangesHasBeenInitialized: () => boolean
}

const SELECTED_AGE_RANGE_KEY = 'selectedAgeRanges'
let selectedAgeRangeStore: SelectedAgeRangeStore | undefined = undefined

export const getSelectedAgeRangeStore = (): SelectedAgeRangeStore => {
  if (selectedAgeRangeStore) return selectedAgeRangeStore

  const [selectedAgeRanges, setSelectedAgeRanges] = makePersisted(
    createSignal<AgeRange[]>([]),
    {
      storage: sessionStorage,
      name: SELECTED_AGE_RANGE_KEY,
    },
  )

  const isAgeRangeSelected = (ageRange: AgeRange) => {
    return selectedAgeRanges().some((item) => item.id === ageRange.id)
  }

  const removeSelectedAgeRange = (ageRange: AgeRange) => {
    setSelectedAgeRanges((prev) =>
      prev.filter((item) => item.id !== ageRange.id),
    )
  }

  const clearSelectedAgeRanges = () => {
    setSelectedAgeRanges([])
  }

  const ageRangesHasBeenInitialized = (): boolean => {
    const storageValue = sessionStorage.getItem(SELECTED_AGE_RANGE_KEY)
    if (!storageValue) return false
    return true
  }

  selectedAgeRangeStore = {
    value: selectedAgeRanges,
    setValue: setSelectedAgeRanges,
    isAgeRangeSelected,
    removeSelectedAgeRange,
    clearSelectedAgeRanges,
    ageRangesHasBeenInitialized,
  }

  return selectedAgeRangeStore
}
