---
import { Typography } from '@/components'
import { cn } from '@/utils'

interface Props {
  bookName: string
  cardClass?: string
}

const { bookName, cardClass } = Astro.props
---

<div
  class={cn(
    'flex flex-col gap-1 px-6 py-4 rounded-2xl items-center bg-bg-student-card w-full border border-solid border-violet-50 shadow-light-purple landscape:h-sm:py-2',
    cardClass,
  )}
>
  <Typography
    element="h1"
    size="t-md"
    weight="bold"
    class="text-white landscape:h-md:text-base md:text-2xl"
  >
    {bookName}
  </Typography>
  <Typography
    element="span"
    size="t-md"
    weight="medium"
    class="text-white landscape:h-md:hidden portrait:md:block portrait:hidden"
  >
    Oral readability test
  </Typography>
</div>
