import { For } from 'solid-js'

import { BookCardItem, Typography } from '@/components'
import type { Book } from '@/core/entities'
import { trackLinkClick } from '@/utils/analytics'

export type BookGridHome = Pick<
  Book,
  'id' | 'childBook' | 'name' | 'author' | 'imageUrl' | 'webBookUrl'
>

const BookListGrid = (props: { books: BookGridHome[] }) => {
  return (
    <div class="mt-10 flex w-full flex-col items-center justify-center">
      <Typography
        element="h1"
        size="h-md"
        weight="bold"
        class="mb-6 text-edsm-neutral-100"
      >
        Choose a book
      </Typography>

      <div class="flex w-full flex-row flex-wrap content-stretch items-center justify-center justify-items-center gap-6">
        <For each={props.books}>
          {(book) => <BookCardItem booksDetail={book as Book} />}
        </For>
      </div>

      <div class="mt-16 flex w-full items-center justify-center">
        <a
          onClick={() => trackLinkClick('See More Books')}
          class="btn-primary-edsoma flex items-center justify-center p-4"
          href="/library"
        >
          See more books
        </a>
      </div>
    </div>
  )
}

export default BookListGrid
