import { For } from 'solid-js'

const QuizLoading = () => {
  return (
    <div class="flex w-full max-w-5xl flex-col">
      <div class="h-40 w-full animate-pulse rounded-2xl bg-edsm-neutral-50" />

      <section class="mx-auto flex h-full w-full flex-col gap-6 p-10">
        <div class="h-8 w-full max-w-xs animate-pulse self-center bg-edsm-neutral-50 md:h-11" />

        <div class="flex w-full flex-col gap-4">
          <For each={Array.from({ length: 4 })}>
            {() => (
              <button class="h-14 animate-pulse rounded-3xl bg-edsm-neutral-50" />
            )}
          </For>
        </div>
      </section>
    </div>
  )
}

export default QuizLoading
