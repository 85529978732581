import { getCookie } from './'

const SPEECH_ENGINE_AUTH_URL = import.meta.env.PUBLIC_SPEECH_ENGINE_AUTH_URL

export function getSpeechBookReadingUrl(bookId: number) {
  const authorization = getCookie('authorization')

  return [
    SPEECH_ENGINE_AUTH_URL,
    '?bookid=',
    bookId,
    '&token=',
    authorization,
  ].join('')
}
