import { createSignal, onMount } from 'solid-js'

import { OnboardingSelectedCard, Typography } from '@/components'
import { getStudentOnboarding, type StudentOnboarding } from '@/store'

const OnboardingSelected = () => {
  const [studentOnboarding, setStudentOnboarding] =
    createSignal<StudentOnboarding>()

  onMount(() => {
    const storedOnboarding = getStudentOnboarding()
    if (storedOnboarding) {
      setStudentOnboarding(storedOnboarding)
    }
  })

  return (
    <div class="flex flex-col-reverse items-center justify-center gap-10 md:flex-col h-sm:gap-4">
      <div class="flex flex-row flex-wrap items-center justify-center gap-6">
        <OnboardingSelectedCard
          title="Your Hobby"
          suggestion={studentOnboarding()?.hobby[0]}
        />
        <OnboardingSelectedCard
          title="Favorite Animal"
          suggestion={studentOnboarding()?.animal[0]}
        />
        <OnboardingSelectedCard
          title="Book Category"
          suggestion={studentOnboarding()?.book[0]}
        />
      </div>

      <div class="flex flex-col items-center justify-center gap-3">
        <Typography
          element="h2"
          size="h-lg"
          weight="bold"
          class="text-white h-sm:text-3xl"
        >
          You’re all set!
        </Typography>
        <Typography element="p" size="h-xs" class="text-white h-sm:hidden">
          Let’s start reading
        </Typography>
      </div>
    </div>
  )
}

export default OnboardingSelected
