---
import { Image } from 'astro:assets'

import volume from '@/assets/volume.svg?url'
import { Typography } from '@/components'
---

<dialog class="modal" id="start-modal">
  <div
    class="modal-box bg-white p-10 flex flex-col gap-10 items-center md:w-104 landscape:h-md:px-8 landscape:h-md:py-4 landscape:h-md:gap-4 landscape:h-md:w-auto"
  >
    <Typography
      element="h1"
      size="h-md"
      weight="bold"
      class="text-edsm-neutral-100 landscape:h-md:text-2xl"
    >
      Before you start...
    </Typography>

    <ul class="list-disc text-left pl-10">
      <li class="text-caribbean-green-60 mb-4 landscape:h-md:mb-2">
        <Typography
          element="span"
          size="t-xl"
          class="text-black landscape:h-md:text-base"
        >
          Find a quiet spot
        </Typography>
      </li>
      <li class="text-caribbean-green-60 mb-4 landscape:h-md:mb-2">
        <Typography
          element="span"
          size="t-xl"
          class="text-black landscape:h-md:text-base"
        >
          Read aloud clearly
        </Typography>
      </li>
      <li class="text-caribbean-green-60">
        <Typography
          element="span"
          size="t-xl"
          class="text-black landscape:h-md:text-base"
        >
          Tap the finish button as soon as you’re done
        </Typography>
      </li>
    </ul>
    <button
      class="btn-secondary-edsoma flex justify-center items-center gap-2 w-auto px-4 h-auto py-3 landscape:h-md:py-2"
      id="replay-instructions-btn"
    >
      <Image
        src={volume}
        alt="Replay instructions"
        width={24}
        height={24}
        class="landscape:h-md:w-4 landscape:h-md:h-4"
      />
      <Typography
        id="replay-instructions-text"
        element="span"
        size="t-sm"
        weight="bold"
      >
        Play instructions
      </Typography>
    </button>
    <button class="btn-primary-edsoma" id="start-modal-btn" disabled>
      Start
    </button>
  </div>
  <div class="modal-backdrop bg-[#0000004d]"></div>
</dialog>
