---
import {
  CrossIcon,
  DrawerVideoDialog,
  LogoutButton,
  OnboardingStudentCard,
  Typography,
} from '@/components'
import { ExternalUrl } from '@/utils/externalUrl.ts'

const ONBOARDING_VIDEO_ID = import.meta.env.PUBLIC_ONBOARDING_VIDEO_ID
const BOOK_VIDEO_ID = import.meta.env.PUBLIC_BOOK_VIDEO_ID
---

<div class="drawer absolute drawer-end">
  <input id="my-drawer-menu" type="checkbox" class="drawer-toggle" />
  <div class="drawer-content"></div>
  <div class="drawer-side z-20">
    <label
      for="my-drawer-menu"
      aria-label="close sidebar"
      class="drawer-overlay checked:bg-edsm-neutral-100 checked:opacity-50"
    ></label>
    <div
      class="menu flex h-screen w-full justify-between divide-y divide-edsm-neutral-30 bg-white p-4 px-8 text-base-content md:w-[480px] flex-nowrap overflow-auto"
    >
      <ul>
        <button
          onclick="document.getElementById('my-drawer-menu').click()"
          class="w-10 h-10 border-2 rounded-full border-violet-70 flex items-center justify-center mb-8 h-md:mb-4"
        >
          <CrossIcon width={20} height={20} color="#7C4BE2" client:load />
        </button>
        <OnboardingStudentCard
          cardClass="bg-gradient-to-b mb-4 from-gradient-color-violet-from to-gradient-color-violet-to w-full border border-solid border-violet-50 shadow-light-purple"
        />
        <!-- <HistoryButton client:load /> -->

        <div class="flex flex-col gap-4">
          <DrawerVideoDialog
            title="Your guide to the app"
            videoID={ONBOARDING_VIDEO_ID}
            client:only="solid-js"
          />

          <DrawerVideoDialog
            title="Book Navigation Guide"
            videoID={BOOK_VIDEO_ID}
            client:only="solid-js"
          />
        </div>
        <div class="w-full flex gap-4 flex-col mt-4 h-md:gap-0">
          <a
            href="/about"
            class="py-2.5 px-6 hover:bg-edsm-neutral-20 rounded-md"
          >
            <Typography
              weight="semibold"
              size="t-lg"
              element="span"
              class="!text-edsm-neutral-100"
            >
              About
            </Typography>
          </a>
          <a
            href={ExternalUrl.support}
            target="_blank"
            rel="noopener noreferrer"
            class="py-2.5 px-6 hover:bg-edsm-neutral-20 rounded-md flex items-center"
          >
            <Typography
              weight="semibold"
              size="t-lg"
              element="span"
              class="!text-edsm-neutral-100"
            >
              Help & FAQs
            </Typography>
            <img src="/external-link.svg" alt="External Link" class="ml-1" />
          </a>
          <a
            href={ExternalUrl.termsOfService}
            target="_blank"
            rel="noopener noreferrer"
            class="py-2.5 px-6 hover:bg-edsm-neutral-20 rounded-md flex items-center"
          >
            <Typography
              weight="semibold"
              size="t-lg"
              element="span"
              class="!text-edsm-neutral-100"
            >
              Terms of Service
            </Typography>
            <img src="/external-link.svg" alt="External Link" class="ml-1" />
          </a>
          <a
            href={ExternalUrl.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
            class="py-2.5 px-6 hover:bg-edsm-neutral-20 rounded-md flex items-center"
          >
            <Typography
              weight="semibold"
              size="t-lg"
              element="span"
              class="!text-edsm-neutral-100"
            >
              Privacy Policy
            </Typography>
            <img src="/external-link.svg" alt="External Link" class="ml-1" />
          </a>
        </div>
      </ul>
      <div>
        <LogoutButton client:load />
      </div>
    </div>
  </div>
</div>

<script>
  import { handleDrawerScroll } from '@/utils'

  document.addEventListener('astro:page-load', () => {
    handleDrawerScroll('my-drawer-menu')
  })
</script>
