import { createSignal } from 'solid-js'
import { makePersisted } from '@solid-primitives/storage'

import type { SessionStorage } from '@/store/session'

interface SearchStore extends SessionStorage<string> {}

const SEARCH_KEY = 'search'
let searchStore: SearchStore | undefined = undefined

export const getSearchStore = (): SearchStore => {
  if (searchStore) return searchStore

  const [search, setSearch] = makePersisted(createSignal<string>(''), {
    storage: sessionStorage,
    name: SEARCH_KEY,
  })

  searchStore = {
    value: search,
    setValue: setSearch,
  }

  return searchStore
}
