import { splitProps, type JSX } from 'solid-js'

interface IconProps extends JSX.SvgSVGAttributes<SVGSVGElement> {
  color: string
  fillColor?: string
}

const Cross = (_props: IconProps) => {
  const [{ height = '100%', width = '100%', fillColor = 'none', color }, rest] =
    splitProps(_props, ['height', 'width', 'color', 'fillColor'])

  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${height} ${width}`}
      height={height}
      width={width}
      fill={fillColor}
    >
      <g id="x">
        <path
          id="Icon"
          d="M15 5L5 15M5 5L15 15"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default Cross
