import { useStore } from '@nanostores/solid'
import { Show, createMemo, onMount } from 'solid-js'
import { FaSolidCheck, FaSolidXmark } from 'solid-icons/fa'
import { navigate } from 'astro:transitions/client'

import { QuizLoading, Typography } from '@/components'
import {
  clearQuizStore,
  loadQuizWithAnswers,
  loadingQuizScoreStore,
  quizStore,
} from '@/store'

declare type confettiOptions = {
  angle: number
  spread: number
  particleCount: number
  origin: { y: number; x: number }
}

declare global {
  function confetti(options: confettiOptions): void
}

const QuizScore = () => {
  const quiz = useStore(quizStore)
  const loadingQuizScore = useStore(loadingQuizScoreStore)

  const correctAnswers = createMemo(() => {
    const correctOptions = quiz().questions.filter((item) => {
      const optionId = item.optionId
      const correctOption = item.question.options.find(
        ({ isCorrect }) => isCorrect,
      )

      return optionId === correctOption?.id
    })

    return correctOptions
  })

  const quizPercentage = createMemo(() => {
    return (correctAnswers().length / quiz().questions.length) * 100
  })

  const goBack = () => {
    return navigate('/library')
  }

  const handleBack = () => {
    goBack().then(() => {
      clearQuizStore()
    })
  }

  onMount(async () => {
    const { bookId, childId } = quiz().questions[0]

    if (!bookId || !childId) return

    await loadQuizWithAnswers(bookId, childId)

    if (quizPercentage() >= 60) {
      confetti({
        angle: 120,
        particleCount: 100,
        spread: 90,
        origin: { y: 0.8, x: 1 },
      })
      confetti({
        angle: 50,
        spread: 90,
        particleCount: 100,
        origin: { y: 0.8, x: 0 },
      })
    }
  })

  return (
    <Show when={!loadingQuizScore()} fallback={<QuizLoading />}>
      <Typography
        element="h2"
        size="h-xs"
        weight="bold"
        class="mx-auto text-edsm-neutral-100 md:text-4xl"
      >
        Scoreboard
      </Typography>

      <div class="flex w-fit flex-col items-center justify-center gap-10 self-center rounded-xl bg-pale-violet-10 p-10">
        <Typography
          element="span"
          size="h-md"
          weight="bold"
          class="text-edsm-neutral-100"
        >
          {[correctAnswers().length, quiz().questions.length].join(' / ')}
        </Typography>

        <div class="flex flex-row gap-4">
          <Typography
            element="span"
            size="t-md"
            class="flex flex-row items-center gap-1 whitespace-nowrap text-edsm-neutral-90"
          >
            <FaSolidCheck size={16} class="text-caribbean-green-60" />
            {[correctAnswers().length, 'Correct'].join(' ')}
          </Typography>
          <Typography
            element="span"
            size="t-md"
            class="flex flex-row items-center gap-1 whitespace-nowrap text-edsm-neutral-90"
          >
            <FaSolidXmark size={16} class="text-edsm-error-60" />
            {[
              quiz().questions.length - correctAnswers().length,
              'Incorrect',
            ].join(' ')}
          </Typography>
        </div>
      </div>

      <button
        class="btn-primary-edsoma animate-enter-right self-center"
        onClick={handleBack}
      >
        Back to library
      </button>
    </Show>
  )
}

export default QuizScore
