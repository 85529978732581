import { Show, createMemo, createResource } from 'solid-js'

import {
  type BookGridHome,
  BookListGrid,
  LoadingBooks,
  type YourBooks,
  YourBooksList,
} from '@/components'
import { childBooks, listBooks } from '@/core/infra/graphql'
import type { Book, ClassAssignment, ReadingResult } from '@/core/entities'

type fetchBooksResponse = {
  listBook: BookGridHome[] | YourBooks[]
  type: 'bookGrid' | 'yourBooks'
}

type ChildBook = {
  childId: number
  bookId: number
  isFavorite: boolean
  isAssigned: boolean
  progress: number
  book: Book
  classAssignment: ClassAssignment
  readingResult: ReadingResult
}

async function fetchBooks(childId: number): Promise<fetchBooksResponse> {
  const yourBooks = await childBooks<ChildBook>({
    fields: ['book', 'isAssigned', 'progress'],
    variables: { childId, assigned: true, started: true, filterType: 'OR' },
    options: {
      book: [
        'id',
        'name',
        'author',
        'imageUrl',
        'webBookUrl',
        { childBook: ['isAssigned', 'progress'] },
      ],
    },
  })

  if (yourBooks.childBooks.length) {
    return {
      listBook: yourBooks.childBooks.map((item: ChildBook) => {
        return {
          ...item.book,
          childBook: {
            isAssigned: item.isAssigned,
            progress: item.progress,
          },
        }
      }) as unknown as YourBooks[],
      type: 'yourBooks',
    }
  }

  const bookGrid = await listBooks<BookGridHome>({
    fields: ['author', 'childBook', 'id', 'imageUrl', 'name', 'webBookUrl'],
    variables: { filter: { childId }, pagination: { page: 1, perPage: 10 } },
    options: { childBook: ['progress'] },
  })

  return {
    listBook: bookGrid.listBook.results,
    type: 'bookGrid',
  }
}

const HomeBooks = (props: { childId: number }) => {
  const [data] = createResource<fetchBooksResponse>(() =>
    fetchBooks(props.childId),
  )

  const hasAssignedBooks = createMemo(() => {
    if (!data()) return false
    const books = data()?.listBook as YourBooks[]
    return books.some((book) => book.childBook.isAssigned)
  })

  return (
    <Show when={data()} fallback={<LoadingBooks />}>
      {data()?.type === 'yourBooks' ? (
        <YourBooksList
          startingTab={hasAssignedBooks() ? 'assigned' : 'allBooks'}
          books={data()?.listBook as YourBooks[]}
        />
      ) : (
        <BookListGrid books={data()?.listBook as BookGridHome[]} />
      )}
    </Show>
  )
}

export default HomeBooks
